"use strict"

export default {
    state: {
        user: {},
        allUserHasAcces: [
            // '2002004001', // ? Suryo Aji
        ]
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        }
    },
    actions: {
        setUser(context, payload) {
            context.commit("SET_USER", payload)
        },
        removeUser(context) {
            context.commit("SET_USER", {})
        }
    },
    getters: {
        user: state => state.user,
        hasAllAccess: state => state.allUserHasAcces.includes(state.user.NIK)
    }
}