import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
// import Rencana from "../views/InputRencanaVaksin.vue";
import Realisasi from "../views/InputRealisasiVaksin.vue";
// import Approve from "../views/ApproveRencanaVaksin.vue";
import ReportMPVC from "../views/ReportMPVC.vue";
import ReportRPVC from "../views/ReportRPVC.vue";
import ReportRPV from "../views/ReportRPV.vue";
// import ReportDSVC from "../views/ReportDSVC.vue";
import MasterVaksin from "../views/MasterVaksin.vue";
import MasterUser from "../views/MasterUser.vue";
import MasterRealisasi from "../views/MasterRealisasi.vue";
import PageNotFound from "../views/PageNotFound.vue";
// import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
        guest: true
    }
  },
  {
    path: "/page-not-found",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
        guest: true
    }
  },
  // {
  //   path: "/input-rencana",
  //   name: "Rencana",
  //   component: Rencana,
  //   meta: {
  //       auth: true
  //   }
  // },
  {
    path: "/input-realisasi",
    name: "Realisasi",
    component: Realisasi,
    meta: {
        auth: true
    }
  },
  // {
  //   path: "/approve-rencana",
  //   name: "Approve",
  //   component: Approve,
  //   meta: {
  //       auth: true
  //   }
  // },
  {
    path: "/master-vaksin",
    name: "MasterVaksin",
    component: MasterVaksin,
    meta: {
        auth: true
    }
  },
  {
    path: "/master-user",
    name: "MasterUser",
    component: MasterUser,
    meta: {
        auth: true
    }
  },
  {
    path: "/master-realisasi",
    name: "MasterRealisasi",
    component: MasterRealisasi,
    meta: {
        auth: true
    }
  },
  {
    path: "/report-mpvc",
    name: "ReportMPVC",
    component: ReportMPVC,
    meta: {
        auth: true
    }
  },
  {
    path: "/report-rpvc",
    name: "ReportRPVC",
    component: ReportRPVC,
    meta: {
        auth: true
    }
  },
  {
    path: "/report-rpv",
    name: "ReportRPV",
    component: ReportRPV,
    meta: {
        auth: true
    }
  },
  // {
  //   path: "/report-dsvc",
  //   name: "ReportDSVC",
  //   component: ReportDSVC,
  //   meta: {
  //       auth: true
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (sessionStorage.getItem('nik')) {
      next();
    } else {
      next({
        path: "/",
      });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (sessionStorage.getItem('nik')) {
      next({
        // path: sessionStorage.getItem('pejabat') == "1" ? "/approve-rencana" : "/input-rencana"
        path: "/input-realisasi"
      });
    } else {
      next();
    }
  } else {
    next({
      path: "/page-not-found"
    });
  }
});


// const doBeforeEach = (to, from, next) => {
  

//   if (store.state.nik !== null) {
//     if (Object.keys(user).length === 0) {
//       // let splitToken = token.split('-')

//       axios
//         .post("http://116.204.128.50:80/api/v1/DetailWorker", tempDetailUser, {
//           // nik: splitToken[1]
//         })
//         .then((response) => {
//           // let data = response.data.data[0]
//           const detail = response.data;

//           store
//             .dispatch("setUser", detail)
//             .then(() => {
//               if (to.name === "Login")
//                 next({
//                   name: "Rencana",
//                 });
//               else next();
//             })
//             .catch((error) => {
//               console.log(error);
//               next({
//                 name: "Login",
//               });
//             });
//         });
//     } else if (to.name === "Login")
//       next({
//         name: "Rencana",
//       });
//     else next();
//   } else {
//     if (to.name == "Login") {
//       next();
//     } else {
//       next(`/login?redirect=${to.fullPath}`);
//     }
//   }
// };

// router.beforeEach(doBeforeEach);
// const axios = require("axios");
// const user = store.getters.user;
// let tempDetailUser = {
//     NikKaryawan: store.state.nik,
// };

// router.beforeEach((to, from, next) => {
//   console.log(store.state.nik)
//   console.log(store.state.isLogin)
//   const axios = require("axios");
//   // const user = store.getters.user;
//   let tempDetailUser = {
//       NikKaryawan: store.state.nik,
//   };
//   if (store.state.nik !== null) {
//     // if (Object.keys(user).length === 0) {
//       // axios.POST("http://116.204.128.50:80/api/v1/DetailWorker", tempDetailUser, {
//         axios
//         .post("http://116.204.128.50:80/api/v1/DetailWorker", tempDetailUser)
//         .then((response) => { 
//         // }).then((response) => {
//           const detail = response.data;
//           store
//             .dispatch("setUser", detail)
//             .then(() => {
//               if (to.name === "Login")
//                 next();
//               else next();
//             })
//             .catch((error) => {
//               console.log(error);
//               next({
//                 name: "Login",
//               });
//             });
//         });
//     // } else if (to.name === "Login")
//     //   next({
//     //     name: "Rencana",
//     //   });
//     // else next();
//   } else {
//     if (to.name == "Login") {
//       next();
//     } else {
//       next(`/login?redirect=${to.fullPath}`);
//     }
//   }
// });
// router.beforeEach((to, from, next) => {
//   if (sessionStorage.getItem('nik') == "") next({ name: 'Login' })
//   else next()
// })
export default router;
