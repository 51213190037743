<template>
  <div>
    <Sidebar />
    <Toolbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="image/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Data Realisasi Vaksin
              </h3>

              <form class="pa-4 mx-3">
                <div class="my-2">
                  <v-row>
                    <v-col cols="6" sm="4" md="4">
                      <v-text-field
                        v-model="NIK_NamaKaryawan"
                        label="NIK Karyawan"
                        :counter="10"
                        :maxlength="10"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-1"
                        @click="listRealisasi"
                        :loading="btnLoading"
                        :disabled="validCari"
                      >
                        Cari
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="mt-10" v-show="dataTable">
                    <v-data-table
                      :headers="headers"
                      :items="itemsRealisasi"
                      :search="search"
                      class="elevation-1"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <!-- <v-btn
                            color="success"
                            dark
                            class="mb-2"
                            @click="downloadExcel()"
                          >
                            Export Excel
                          </v-btn> -->

                          <!-- jarak judul -->
                          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                          <v-dialog
                            v-model="dialogSertifikat"
                            max-width="800px"
                          >
                            <v-card>
                              <v-form ref="form">
                                <v-card-title>
                                  <span class="headline"
                                    >Sertifikat Vaksinasi</span
                                  >
                                </v-card-title>

                                <v-card-text>
                                  <!-- <v-alert class="mx-4" type="info">
                                    <div>
                                      Jika PDF hanya bisa didownload
                                    </div>
                                  </v-alert> -->
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-img
                                          v-bind:src="
                                            'data:image/jpeg;base64,' +
                                              file_sertifikat
                                          "
                                        ></v-img>
                                      </v-col>
                                    </v-row>
                                    <!-- <v-row
                                      v-if="
                                        this.editedItem.EXTENSION_RAPORT ==
                                          'pdf'
                                      "
                                    >
                                      <a
                                        :href="
                                          'data:application/pdf;base64,' +
                                            this.items_cekblob.FILEBLOB_RAPORT
                                        "
                                        style="color:green"
                                        download
                                      >
                                        Download Lampiran
                                      </a>
                                    </v-row> -->
                                    <!-- <v-row>
                                      <a
                                        :href="
                                          'data:image/jpeg;base64,' +
                                            file_sertifikat
                                        "
                                        download
                                      >
                                        Download Sertifikat
                                      </a>
                                    </v-row> -->
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDialogSertifikat"
                                  >
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog" max-width="600px">
                            <v-card>
                              <v-form ref="form">
                                <v-card-title>
                                  <span class="headline">Edit Realisasi</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItem.NO_TICKET"
                                          label="No Ticket"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.NIK"
                                          label="NIK"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.NAMA"
                                          label="NAMA"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <!-- <v-text-field
                                          v-model="editedItem.NAMA_VAKSIN"
                                          label="Jenis Vaksinasi"
                                        ></v-text-field> -->
                                        <v-select
                                          :items="itemsVaksinWajib"
                                          item-text="NAMA"
                                          item-value="ID_VAKSIN"
                                          label="Jenis Vaksinasi"
                                          v-model="jenisvaksinasi"
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItem.NOMOR_SERTIFIKAT"
                                          label="ID Sertifikat"
                                          required
                                          :rules="rulesSertifikasi"
                                          :counter="24"
                                          :maxlength="24"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.VAKSINASI_KE"
                                          label="Vaksinasi Ke"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <!-- <v-text-field
                                      v-model="editedItem.TANGGAL_VAKSINASI"
                                      label="Tanggal Vaksinasi"
                                    ></v-text-field> -->
                                        <v-menu
                                          v-if="editedItem.VAKSINASI_KE > 1"
                                          v-model="menu2"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="computedDateFormatted"
                                              label="Pilih Tanggal Vaksinasi"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="date"
                                            :max="nowDate"
                                            :min="tglVaksin1"
                                            @input="menu2 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                        <v-menu
                                          v-else
                                          v-model="menu2"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="computedDateFormatted"
                                              label="Pilih Tanggal Vaksinasi"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="date"
                                            :max="nowDate"
                                            @input="menu2 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveWajib"
                                    v-show="valid"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>
                          <!-- dialog edit booster -->
                          <v-dialog v-model="dialogBooster" max-width="600px">
                            <v-card>
                              <v-form ref="form">
                                <v-card-title>
                                  <span class="headline">Edit Realisasi</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItem.NO_TICKET"
                                          label="No Ticket"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.NIK"
                                          label="NIK"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.NAMA"
                                          label="NAMA"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <!-- <v-text-field
                                          v-model="editedItem.NAMA_VAKSIN"
                                          label="Jenis Vaksinasi"
                                        ></v-text-field> -->
                                        <v-select
                                          :items="itemsVaksinBooster"
                                          item-text="NAMA"
                                          item-value="ID_VAKSIN"
                                          label="Jenis Vaksinasi"
                                          v-model="jenisvaksinasi"
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItem.NOMOR_SERTIFIKAT"
                                          label="ID Sertifikat"
                                          required
                                          :rules="rulesSertifikasi"
                                          :counter="24"
                                          :maxlength="24"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.VAKSINASI_KE"
                                          label="Vaksinasi Ke"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <!-- <v-text-field
                                      v-model="editedItem.TANGGAL_VAKSINASI"
                                      label="Tanggal Vaksinasi"
                                    ></v-text-field> -->
                                        <v-menu
                                          v-model="menu2"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="computedDateFormatted"
                                              label="Pilih Tanggal Vaksinasi"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="date"
                                            :max="nowDate"
                                            :min="tglVaksin1"
                                            @input="menu2 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveBooster"
                                    v-show="valid"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>
                          <v-spacer></v-spacer>

                          <!-- search -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.TANGGAL_VAKSINASI`]="{ item }">
                        <td class="text-xs-right">
                          {{ formatDate(item.TANGGAL_VAKSINASI) }}
                        </td>
                      </template>
                      <template v-slot:[`item.sertifikat`]="{ item }">
                        <!-- <v-icon small class="mr-2" @click="viewSertifikat(item)">
                          mdi-pencil
                        </v-icon> -->
                        <a href="#" @click="viewSertifikat(item)"
                          >Lihat Sertifikat</a
                        >
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <!-- <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon> -->
                        <v-icon v-if="item.FLAG_VAKSIN == 'Wajib'" small class="mr-2" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon v-else-if="item.FLAG_VAKSIN == 'Booster'" small class="mr-2" @click="editBooster(item)">
                          mdi-pencil
                        </v-icon>

                        <v-icon v-if="item.JUMLAH == 3 && item.VAKSINASI_KE == 3 && item.FLAG_VAKSIN == 'Booster'" small @click="deleteBooster(item)">
                          mdi-delete
                        </v-icon>
                        <v-icon v-else-if="item.JUMLAH == 2 && item.VAKSINASI_KE == 2 && item.FLAG_VAKSIN == 'Booster'" small @click="deleteBooster(item)">
                          mdi-delete
                        </v-icon>
                        <v-icon v-else-if="item.JUMLAH == 2 && item.VAKSINASI_KE == 2 && item.FLAG_VAKSIN == 'Wajib'" small @click="deleteItem(item)">
                          mdi-delete
                        </v-icon>
                        <v-icon v-else-if="item.JUMLAH == 1 && item.VAKSINASI_KE == 1 && item.FLAG_VAKSIN == 'Wajib'" small @click="deleteItem(item)">
                          mdi-delete
                        </v-icon>
                      </template>

                      <!-- <template v-slot:no-data>
                    <h3>No Data</h3>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                    </v-data-table>
                  </div>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Toolbar from "@/components/Toolbar.vue";
const axios = require("axios");
export default {
  name: "Home",
  components: {
    Sidebar,
    Toolbar,
  },

  mounted() {
    this.role = sessionStorage.getItem("role");
    this.cabang = sessionStorage.getItem("cabang");
    this.niklogin = sessionStorage.getItem("nik");
    console.log(this.role);
    console.log(this.cabang);
    console.log(this.niklogin);
    
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    nowDate: new Date().toISOString().substr(0,10),
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    modelKaryawan: null,
    cariKaryawan: null,
    NIK_NamaKaryawan: "",
    jenisvaksinasi: null,
    dataTable: false,
    role: "role",

    dialog: false,
    dialogSertifikat: false,
    dialogKary: false,
    // dialogDelete: false,
    model: null,
    search: null,
    btnLoading: false,
    overlay: false,
    itemsVaksinWajib: [],
    itemsVaksinBooster: [],
    date: null,
    menu: false,
    modal: false,
    menu2: false,
    // search: "",
    nik: "",
    nama: "",
    rolePIC: "",
    id_unit: "",
    id_bagian: [],
    id_cabang: [],
    file_sertifikat: "",
    unit: "",
    bagian: [],
    cabang: [],
    code: "",
    nosertifikat: "",
    tglVaksin1: "",

    karyawan: [],

    headers: [
      {
        text: "No Ticket",
        align: "start",
        sortable: true,
        value: "NO_TICKET",
      },
      { text: "NIK", value: "NIK" },
      { text: "Nama", value: "NAMA" },
      // { text: "ID Vaksinasi", value: "ID_VAKSIN" },
      { text: "Jenis Vaksinasi", value: "NAMA_VAKSIN" },
      { text: "Vaksinasi Ke", value: "VAKSINASI_KE" },
      { text: "Tanggal Vaksinasi", value: "TANGGAL_VAKSINASI" },
      { text: "Sertifikat", value: "sertifikat", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    loadingTable: false,

    showDepartement: false,
    itemsTableUser: [],
    itemsRealisasi: [],
    items_list: [],
    items_unit: [],
    items_bagian: [],
    items_cabang: [],
    editedIndex: -1,
    editedItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },

    chooseKar: {
      nik: "",
      nama: "",
    },
    defaultItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },

    rulesSertifikasi: [
      (v) => !!v || "No Sertifikat harus diisi",
      // (v) => !v || v.length <= 26 || "Jumlah Karakter Melebihi 26!",
      (v) => !v || v.length == 24 || "No Sertifikat Harus 24 Karakter!",
      // (v) => !v || /(?=.*[A-Za-z0-9])/.test(v) || "Harus Mengandung Huruf dan Angka",
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    fields() {
      if (!this.modelKaryawan) return [];

      return Object.keys(this.modelKaryawan).map((key) => {
        return {
          key,
          value: this.modelKaryawan[key] || "n/a",
        };
      });
    },
    itemsKaryawan() {
      return this.entries.map((entry) => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },
    valid() {
      let jenis = this.jenisvaksinasi;
      let tgl = this.editedItem.TANGGAL_VAKSINASI;
      let sertifikat = this.editedItem.NOMOR_SERTIFIKAT;
      return jenis != null && tgl != null && sertifikat.length == 24;
    },
    validCari(){
      return this.NIK_NamaKaryawan.length != 10
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {},

    editItem(item) {
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.date = this.editedItem.TANGGAL_VAKSINASI;
      this.itemVaksinWajib();
      if(this.editedItem.VAKSINASI_KE > 1){
        this.getPreviousDate();
      }
      this.jenisvaksinasi = this.editedItem.ID_VAKSIN;
      this.dialog = true;
    },
    editBooster(item) {
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.date = this.editedItem.TANGGAL_VAKSINASI;
      this.itemVaksinBooster();
      this.getPreviousDate();
      this.jenisvaksinasi = this.editedItem.ID_VAKSIN;
      this.dialogBooster = true;
    },
    

    viewSertifikat(item) {
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(this.editedItem)
      this.overlay = true;
      const axios = require("axios");
      const getSertifikat = {
        code: 5,
        data: {
          notiket: this.editedItem.NO_TICKET,
        },
      };
      axios
        .post(this.$baseurl + "MasterRealisasi", getSertifikat, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          // console.log(hasil.data)
          this.file_sertifikat = hasil.message[0].FILE_PATH_PENDUKUNG;
          // console.log(this.file_sertifikat);
          this.overlay = false;
          this.dialogSertifikat = true;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Detail Tidak Ditemukan",
          });
          this.overlay = false;
        });
    },

    deleteItem(item) {
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        html: `Untuk Menghapus Data Vaksinasi ke-${this.editedItem.VAKSINASI_KE} dari <br/> NIK: ${this.editedItem.NIK} <br/> Nama: ${this.editedItem.NAMA}`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          
          // console.log(this.editedItem);
          const deleteRealisasi = {
            code: "6",
            data: {
              notiket: this.editedItem.NO_TICKET,
              nik: this.editedItem.NIK
            },
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   JWT: varToken,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          axios
            .post(this.$baseurl + "MasterRealisasi", deleteRealisasi, {
              withCredentials: true,
            })
            .then((response) => {
              // console.log(response.data.message.message);
              if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.message.message == "OK") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  html: `Data Vaksinasi Ke-${this.editedItem.VAKSINASI_KE} dari <br/> NIK: ${this.editedItem.NIK} <br/> Nama: ${this.editedItem.NAMA} <br/> Telah berhasil dihapus <br/> ${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`,
                });
                this.listRealisasi();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menghapus",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });

      // this.dialogDelete = true;
    },

    deleteBooster(item) {
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        html: `Untuk Menghapus Data Vaksinasi ke-${this.editedItem.VAKSINASI_KE} dari <br/> NIK: ${this.editedItem.NIK} <br/> Nama: ${this.editedItem.NAMA}`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          
          // console.log(this.editedItem);
          const deleteRealisasi = {
            code: "9",
            data: {
              notiket: this.editedItem.NO_TICKET,
              nik: this.editedItem.NIK
            },
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   JWT: varToken,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          axios
            .post(this.$baseurl + "MasterRealisasi", deleteRealisasi, {
              withCredentials: true,
            })
            .then((response) => {
              // console.log(response.data.message.message);
              if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.message.message == "OK") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  html: `Data Vaksinasi Ke-${this.editedItem.VAKSINASI_KE} dari <br/> NIK: ${this.editedItem.NIK} <br/> Nama: ${this.editedItem.NAMA} <br/> Telah berhasil dihapus <br/> ${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`,
                });
                this.listRealisasi();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menghapus",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });
    },

    close() {
      this.dialog = false;
      this.dialogBooster = false;
      // this.chooseKar.NIK = "";
      // this.chooseKar.NAMA = "";
      // this.rolePIC = "";
      // this.unit = "";
      // this.cabang = [];
      // this.bagian = [];
      // this.clearBagianCabang;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogSertifikat() {
      this.dialogSertifikat = false;
    },

    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },

    saveWajib() {
      const No_Ticket = this.editedItem.NO_TICKET;
      const ID_Vaksin = this.jenisvaksinasi;
      const ID_Sertifikat = this.editedItem.NOMOR_SERTIFIKAT;
      const Tgl_Vaksin = this.date;
      const NIK = sessionStorage.getItem("nik");
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Mengubah Data Realisasi",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          // console.log(No_Ticket);
          // console.log(ID_Vaksin);
          // console.log(Tgl_Vaksin);
          const editRealisasi = {
            code: "2",
            data: {
              ID_Vaksin: ID_Vaksin,
              ID_Sertifikat: ID_Sertifikat,
              Tgl_Vaksin: Tgl_Vaksin,
              No_Ticket: No_Ticket,
              Creator_Editor: NIK,
            },
          };
          // console.log(editVaksinasi);
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   JWT: varToken,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          const axios = require("axios");
          axios
            .post(this.$baseurl + "MasterRealisasi", editRealisasi, {
              withCredentials: true,
            })
            .then((response) => {
              // console.log(response.data.message.message);
              if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.message.message == "OK") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: "Realisasi Telah Diubah",
                });
                this.listRealisasi();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Mengubah",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Mengubah",
              });
            });
        }
      });
      this.close();
    },

    saveBooster() {
      const No_Ticket = this.editedItem.NO_TICKET;
      const ID_Vaksin = this.jenisvaksinasi;
      const ID_Sertifikat = this.editedItem.NOMOR_SERTIFIKAT;
      const Tgl_Vaksin = this.date;
      const NIK = sessionStorage.getItem("nik");
      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Mengubah Data Realisasi",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          // console.log(No_Ticket);
          // console.log(ID_Vaksin);
          // console.log(Tgl_Vaksin);
          const editRealisasi = {
            code: "8",
            data: {
              ID_Vaksin: ID_Vaksin,
              ID_Sertifikat: ID_Sertifikat,
              Tgl_Vaksin: Tgl_Vaksin,
              No_Ticket: No_Ticket,
              Creator_Editor: NIK,
            },
          };
          // console.log(editVaksinasi);
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   JWT: varToken,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          const axios = require("axios");
          axios
            .post(this.$baseurl + "MasterRealisasi", editRealisasi, {
              withCredentials: true,
            })
            .then((response) => {
              // console.log(response.data.message.message);
              if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.message.message == "OK") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: "Realisasi Telah Diubah",
                });
                this.listRealisasi();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Mengubah",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Mengubah",
              });
            });
        }
      });
      this.close();
    },

    dataKaryawan() {
      // axios
      //   .post("https://hrindomaret.com/api/covidform/getUserHO", {})
      //   .then((response) => {
      //     this.karyawan = response.data;
      //     console.log(this.karyawan);
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     alert("Tidak Dapat Mengambil Karyawan");
      //   });
    },

    listRealisasi() {
      this.btnLoading = true;
      if(this.NIK_NamaKaryawan.length >= 6){
        const searchKary = {
        code: "1",
        data: {
          Search: this.NIK_NamaKaryawan,
          Role: this.role,
          NIK_Login: this.niklogin
        },
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "MasterRealisasi", searchKary, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log(response.data.SearchWorkerResult.length);
          // const result = response.data;

          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.itemsRealisasi = response.data.message;
            this.btnLoading = false;
            this.dataTable = true;
            this.cobatgl = this.formatDate(
              response.data.message.TANGGAL_VAKSINASI
            );
            // this.dialogKary = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Karyawan Tidak Ditemukan",
          });
        });
      }else{
        this.btnLoading = false;
        this.$swal({
            icon: "warning",
            title: "Perhatian!",
            text: "Min 6 Karakter Input",
          });
      }
      
    },

    clearBagianCabang() {
      this.unit = "";
      this.bagian = [];
      this.cabang = [];
    },

    clearBagian() {
      this.unit = "";
      this.bagian = [];
    },

    clearCabang() {
      this.unit = "";
      this.cabang = [];
    },

    clearForm() {
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.rolePIC = "";
      this.unit = "";
      this.cabang = [];
      this.bagian = [];
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    itemVaksinWajib() {
      const axios = require("axios");
      const dataVaksin = {
        code: "7",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "MasterVaksin", dataVaksin, { withCredentials: true })
        .then((response) => {
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.itemsVaksinWajib = response.data.message;
            // console.log(response.data.message);
          }

          // response.data.message.map(function(Nama, key) {
          //   this.itemsVaksin.push(Nama);
          // });
        })
        .catch((error) => {
          console.log(error);
          alert("Tidak Dapat Mengambil Items Vaksin");
        });
    },

    itemVaksinBooster() {
      const axios = require("axios");
      const dataVaksin = {
        code: "8",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "MasterVaksin", dataVaksin, { withCredentials: true })
        .then((response) => {
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.itemsVaksinBooster = response.data.message;
            // console.log(response.data.message);
          }

          // response.data.message.map(function(Nama, key) {
          //   this.itemsVaksin.push(Nama);
          // });
        })
        .catch((error) => {
          console.log(error);
          alert("Tidak Dapat Mengambil Items Vaksin");
        });
    },

    getPreviousDate() {
      const axios = require("axios");
      const data = {
        code: 10,
        data: {
          vaksinasi_ke: this.editedItem.VAKSINASI_KE - 1,
          nik: this.editedItem.NIK
        },
      };
      axios
        .post(this.$baseurl + "MasterRealisasi", data, { withCredentials: true })
        .then((response) => {
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
              const hasil = response.data.message[0];
              let myFutureDate = new Date(hasil.TANGGAL_VAKSINASI);
              let valueDate = myFutureDate.setDate(myFutureDate.getDate()+ 1);
              this.tglVaksin1 = new Date(valueDate).toISOString().substr(0, 10);
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Tidak Dapat Mengambil Tanggal");
        });
    },
  },
};
</script>
