<template>
  <v-app>
    <!-- <Navbar v-show="getStatusLogin"/> -->
    <v-dialog v-model="warningModal" width="500">
      <v-card color="primary">
        <v-card-title class="text-h5 blue lighten-2">
          {{ text }}
        </v-card-title>

        <v-card-text class="text-h7 blue lighten-2">
          Web Akan Terlogout Otomatis Jika Tidak Ada Aktivitas
        </v-card-text>
      </v-card>
      <template v-slot:action="{}"> </template>
    </v-dialog>

    <router-view />

    <v-footer color="primary" dark app>
      <span class="white--text" style="width: 100%;text-align: center"
        >Copyright © {{ new Date().getFullYear() }} - Software Development II, Indomaret Group
        v1.2.2</span
      >
    </v-footer>
  </v-app>
</template>

<script>
// import { Sidebar } from "@/components/Sidebar.vue";
// import Navbar from "./components/Navbar";
import { mapActions } from "vuex";

export default {
  name: "App",

  data:() => ({
    events: ["click", "scroll", "mousemove", "mousedown", "keypress", "load"],
    warningTimer: null,
    logoutTimer: null,
    text: "Tidak Ada Aktivitas Selama 15 Menit",
    warningModal: false,
  }),

  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    }, this);

    this.resetTimer();
  },

  component: {
    // Sidebar,
    // Navbar,
  },

  methods: {
    ...mapActions({
      removeUser: "removeUser",
    }),

    setTimers() {
      if (this.$route.name !== "Login") {
        this.warningTimer = setTimeout(
          this.waningMessage,
          0.25 * 60 * 60 * 1000
        ); // before 30 minutes
        this.logoutTimer = setTimeout(this.logOutUser, 0.5 * 60 * 60 * 1000); // 30 minutes
        // this.tokenTimer = setTimeout(this.tokenExpired, 8 * 60 * 60 * 1000)

        this.warningModal = false;
      }
    },

    waningMessage() {
      this.warningModal = true;
    },

    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);

      this.setTimers();
    },

    logOutUser() {
      this.removeUser();
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push({ name: "Login" });

      this.warningModal = false;
      // window.location.reload();
    },

  },

  // data() {
  //   return {
  //     $baseurl1: "http://localhost/",
  //     $baseurl2: "http://116.204.128.50:80/",
  //     $baseurl: this.$baseurl2,
  //   };
  // },
  // computed: {
  //       getStatusLogin: function () {
  //           return this.$store.state.isLogin;
  //       }
  //   }
};
</script>
