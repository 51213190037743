<template>
  <!-- <v-app id="inspire"> -->
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{nama}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{nik}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        
        <v-list-item v-for="item in items" :key="item.title" @click="redirectTo(item.route)" v-show="rolePIC(item.show)" 
          link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.subtitle2 }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <!-- <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <span class="hidden-sm-and-down">Database Vaksin Covid-19</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="body-2"
        >Logout</v-toolbar-title
      >
      <v-btn icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar> -->

  <!-- </v-app> -->
</template>

<script>

import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    // drawer: null,
    nama: "",
    nik: "",
    userRole: "",
    userPejabat: "",
    items: [
      // { title: "Input Rencana Vaksin", subtitle: "Untuk Diri Sendiri", text:"coba test", icon: "mdi-file-document-edit", to: "/input-rencana", route:"Rencana", show: "rencana" },
      // { title: "Approve Rencana Vaksin", subtitle: "Permohonan dari", subtitle2: "Tim Kerja",  icon: "mdi-text-box-check", to: "/approve-rencana", route:"Approve", show: "approve" },
      { title: "Input Realisasi Vaksin", subtitle: "Untuk Diri Sendiri", icon: "mdi-text-box-plus", to: "input-realisasi", route:"Realisasi", show: "realisasi" },
      { title: "Master Vaksin", icon: "mdi-needle", to: "/master-vaksin", route:"MasterVaksin", show: "m_vaksin" },
      { title: "Master User", icon: "mdi-account", to: "/master-user", route:"MasterUser", show: "m_user" },
      { title: "Data Realisasi Vaksin", icon: "mdi-account-edit", to: "/master-realisasi", route:"MasterRealisasi", show: "m_realisasi" },
      { title: "Report MPVC", icon: "mdi-file-document", to: "/report-mpvc", route:"ReportMPVC", show: "MPVC" },
      { title: "Report RPVC", icon: "mdi-file-document", to: "/report-rpvc", route:"ReportRPVC", show: "RPVC" },
      { title: "Report Vaksinasi", icon: "mdi-file-document", to: "/report-rpv", route:"ReportRPV", show: "RPV" },
      // { title: "Report DSVC", icon: "mdi-file-document", to: "/report-dsvc", route:"ReportDSVC", show: "DSVC" },
    ],
  }),

  mounted() {
    this.nama = sessionStorage.getItem('nama');
    this.nik = sessionStorage.getItem('nik');
    this.userRole = sessionStorage.getItem('role');
    // this.userPejabat = sessionStorage.getItem('pejabat');
    this.drawer = false
  },

  computed: {
    ...mapGetters({
      sideBar: "drawer",
      user: "user",
    }),

    drawer: {
      get() {
        return this.sideBar;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },

  methods: {
    ...mapActions({
      setDrawer: "setDrawer",
    }),

    redirectTo(item) {
      this.$router.push({
        name: item,
      });
      // path = `/input-rencana`;
      // this.$router.push(path);
      this.setDrawer(false);
    },

    rolePIC(item){
      const userRole = sessionStorage.getItem('role');
      // const userPejabat = sessionStorage.getItem('pejabat');
      // console.log(userPejabat);
      // console.log(userPejabat == "1" ? "rencana" : "")
      // console.log(item == "m_user")
      if(userRole == "PIC HO"){
        // return item != "m_user" && item != "m_vaksin" && item != (userPejabat == "1" ? "rencana" : "")
        return item != "m_user" && item != "m_vaksin" && item != "m_realisasi"
      }else if(userRole == "HR Cabang"){
        // return item != "m_user" && item != "m_vaksin" && item != userPejabat == "1" ? "rencana" : ""
        return item != "m_user" && item != "m_vaksin"
      }else if(userRole == ""){
        // return item != "m_user"  && item != "m_vaksin" && item != "RPVC" && item != "DSVC" && item != "MPVC" && item != (userPejabat == "1" ? "rencana" : "")
        return item != "m_user"  && item != "m_vaksin" && item != "m_realisasi" && item != "RPVC" && item != "MPVC" && item != "RPV"
      }else{
        return true
      }
    }
  },
};
</script>
