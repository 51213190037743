<template>
  <v-app id="insider">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-img src="image/Indomaret.png" aspect-ratio="3"></v-img>
          <v-card class="mx-auto">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Pendataan Vaksinasi Covid-19</v-toolbar-title>
            </v-toolbar>
            <v-card-text lazy-validation>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  label="NIK"
                  v-model="nik"
                  prepend-icon="mdi-account-circle"
                  type="text"
                  :rules="nikRules"
                  v-on:keypress="isNumber($event)"
                  :maxlength="10"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  autocomplete="off"
                  label="Password"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="primary"
                :loading="btnLoading"
                :disabled="!valid"
                @click="doLogin"
                >Login</v-btn
              > </v-card-actions
            ><br />
            <template>
              <!-- <v-footer padless>
                <v-col class="text-center" cols="12">
                  <v-text class="caption">Version 1.0.0</v-text><br />
                  <v-icon>mdi-copyright</v-icon>
                  <v-text class="caption">
                    {{ new Date().getFullYear() }} - Software & Development II,
                    &nbsp;
                    <a href="https://indomaret.co.id/" target="_blank"
                      >Indomaret Group</a
                    >
                  </v-text>
                </v-col>
              </v-footer> -->
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      // $baseurl: "http://localhost/",
      // $baseurl: "http://116.204.128.50:80/",
      // $baseurl: "https://ms.hrindomaret.com/",
      showPassword: false,
      hasil: "",
      //valid: false,
      btnLoading: false,
      drawer: null,
      snackbar: false,
      valid: true,
      nik: "",
      password: "",
      nikRules: [
        (v) => !!v || "NIK harus diisi",
        (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
      ],
      passwordRules: [(v) => !!v || "Password harus diisi"],
    };
  },

  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        this.doLogin();
      }
    });

    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
  },

  methods: {
    ...mapActions({
      // setToken: "setToken",
      setUser: "setUser",
    }),
    reset() {
      this.$refs.form.reset();
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },
    
    doLogin() {
      this.btnLoading = true;
      // if (this.nik == 2015169897 && this.password == 123) {
      //   this.btnLoading = false;
      //   // this.$swal({
      //   //   icon: "success",
      //   //   title: "Success!",
      //   //   text: "Berhasil Login",
      //   // });
      //   this.$router.push({ name: "Rencana" });
      // } else {
      //   this.btnLoading = false;
      //   this.$swal({
      //     icon: "error",
      //     title: "Maaf!",
      //     text: "Mohon Sesuaikan NIK dan Password dengan ESS",
      //   });
      // }
      const tempUser = {
        Data: {
          nik: this.nik,
          pass: this.password,
        },
      };
      const tempDetailUser = {
        nik: this.nik,
        password: "dummy",
      };

      const axios = require("axios");
      axios
        // .post(this.$baseurl + "Login", tempUser, { withCredentials: true })
        .post("https://api.hrindomaret.com/api/ESS/Dev/Login", tempUser, { withCredentials: true })
        // .post("https://api-stag.hrindomaret.com/api/ESS/Stag/Login", tempUser, { withCredentials: true })
        .then((res) => {
          const hasil = res.data;
          console.log(hasil)
          if (hasil.Status == "1") {
            // sessionStorage.setItem(
            //   "token",
            //   hasil.Message.Token.GetTokenForResult
            // );
            // sessionStorage.setItem("role", hasil.Message.Role);
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   JWT: varToken,
            //   // NIK: this.nik,
            // };
            // sessionStorage.setItem("nik", "2015169897");
            // sessionStorage.setItem("nama", "DIMAS RIZKY ROMADHON");
            // sessionStorage.setItem("ktp", "3578230502960001");
            // sessionStorage.setItem("nohp", "081335383577");
            // sessionStorage.setItem("nik_atasan", "2013433131");
            // sessionStorage.setItem("nama_atasan", "YANI MAHENDRA H ST");
            // sessionStorage.setItem("nohp_atasan", "08131311113");
            // sessionStorage.setItem("cabang", "00");
            // sessionStorage.setItem("pt", "IDM");
            // sessionStorage.setItem("bagian", "SOFTWARE DEVELOPMENT II");
            // this.btnLoading = false;
            // const path = `/input-rencana`;
            // if (this.$route.path !== path) this.$router.push(path);

            axios
              .post("https://api.hrindomaret.com/api/loginvalidess/Dev", tempDetailUser, {
              // .post("https://api-stag.hrindomaret.com/api/loginvalidess/Stag", tempDetailUser, {
                withCredentials: true
              })
              .then((response) => {
                // this.btnLoading = false;
                const detail = response.data.data[0];
                // console.log(detail.NamaKaryawan)
                // this.$store.state.user=detail;
                // this.setUser(detail);
                sessionStorage.setItem("role", detail.RoleDVC);
                sessionStorage.setItem("nik", detail.NikKaryawan);
                sessionStorage.setItem("nama", detail.NamaKaryawan);
                sessionStorage.setItem("ktp", detail.NoKTP);
                sessionStorage.setItem("nohp", detail.NomorHandphone);
                sessionStorage.setItem("nik_atasan", detail.NikAtasan);
                sessionStorage.setItem("nama_atasan", detail.NamaAtasan);
                sessionStorage.setItem("nohp_atasan", detail.NomorHpAtasan);
                sessionStorage.setItem("cabang", detail.ID_Lokasi);
                sessionStorage.setItem("pt", detail.ID_PT);
                sessionStorage.setItem("bagian", detail.Organization_Number);
                sessionStorage.setItem("nama_pt", detail.PT);
                sessionStorage.setItem("nama_cabang", detail.Lokasi);
                sessionStorage.setItem("nama_bagian", detail.Departemen);
                sessionStorage.setItem("job_class", detail.JobClass);
                sessionStorage.setItem("parent_organization_id", detail.Parent_Organization_Number);
                sessionStorage.setItem("parent_organization", detail.Parent_Organization);
                sessionStorage.setItem("grandparent_organization_id", detail.GrandParent_Organization_Number);
                sessionStorage.setItem("grandparent_organization", detail.GrandParent_Organization);

                // const path = `/input-rencana`;
                // if (this.$route.path !== path) this.$router.push(path);
                
                // const getJobClass = {
                //   code: "8",
                //   data: {
                //     jobclass:  sessionStorage.getItem("job_class"),
                //     syarat: "F",
                //   },
                // };
                // axios
                //   .post(this.$baseurl + "api/v1/MasterUser", getJobClass, {
                //     headers,
                //   })
                //   .then((response) => {
                //     this.btnLoading = false;
                //     const hasil = response.data.result;
                //     console.log(hasil);
                //       let path = ``;
                //     if(hasil.message == "OK"){
                //       path = `/approve-rencana`;
                //       sessionStorage.setItem("pejabat", "1");
                //     }else{
                //       path = `/input-rencana`;
                //        sessionStorage.setItem("pejabat", "0");
                //     }

                //     if (this.$route.path !== path) this.$router.push(path);
                //     // this.$router.push('input-rencana').catch(err => {});

                //     // console.log(detail.NamaKaryawan)
                //   })
                //   .catch((error) => {
                //     this.btnLoading = false;
                //     console.log(error);
                //     this.$swal({
                //       icon: "error",
                //       title: "Maaf!",
                //       text: "Gagal, Service Job Class Bermasalah",
                //     });
                //   });
                  let path = ``;
                      path = `/input-realisasi`;

                    if (this.$route.path !== path) this.$router.push(path);
                
              })
              .catch((error) => {
                this.btnLoading = false;
                console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal, Service Detail Worker Bermasalah",
                });
              });
          } else if (hasil.Status != "1") {
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Mohon Sesuaikan NIK dan Password Anda dengan ESS",
            });
          } else {
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: hasil.Result,
            });
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.btnLoading = false;
            this.error_message = e.res.data.error;
            // this.snackbar=true;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Mohon Periksa Jaringan Anda atau Server Kami Sedang Bermasalah",
            });
          }
        });
    },
  },
};
</script>
