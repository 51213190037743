<template>
  <div class="laporan">
    <Sidebar />
    <Toolbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="image/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Monitoring Pendataan Vaksinasi Covid-19
              </h3>

              <v-alert class="mx-4" type="info">
                <!-- <div v-show="this.userRole == 'ETD'">
                  Mohon memilih Unit, Cabang, dan Direktorat untuk melihat
                  <i>report</i> karyawan Head Office
                </div> -->
                <div v-show="this.userRole == 'ETD'">
                  Mohon memilih Flag Vaksin dan Unit untuk melihat
                  <i>report</i> karyawan
                </div>
                 <div v-show="this.userRole == 'PIC HO'">
                  Mohon memilih Flag Vaksin dan Unit untuk melihat
                  <i>report</i> karyawan
                </div>
                <div v-show="this.userRole == 'HR Cabang'">
                  Mohon memilih Flag Vaksin dan Unit untuk melihat
                  <i>report</i> karyawan
                </div>
              </v-alert>

              <form class="pa-4 mx-3">
                <div class="my-2">
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="3">
                      <v-select
                        :items="items_flag_vaksin"
                        label="Flag Vaksin"
                        v-model="flag_vaksin"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3">
                      <v-autocomplete
                        :items="items_unit"
                        label="Unit"
                        v-model="unit"
                        item-text="NamaUnit"
                        item-value="AliasUnit"
                        @change="dataCabang"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3">
                      <v-autocomplete
                        :items="items_cabang"
                        label="Cabang"
                        v-model="cabang"
                        item-text="AREANAME"
                        item-value="AREAID"
                        @change="dataDirektorat"
                        return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3" >
                      <v-autocomplete v-if="this.userRole == 'ETD' && this.cabang.AREAID == '00' || this.userRole == 'PIC HO'"
                        :items="items_direktorat"
                        label="Direktorat"
                        v-model="direktorat"
                        item-text="NAME"
                        item-value="OMOPERATINGUNITNUMBER"
                        @change="dataBagian"
                        return-object
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.OMOPERATINGUNITNUMBER }} -
                          {{ data.item.NAME }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.OMOPERATINGUNITNUMBER }} -
                          {{ data.item.NAME }}
                        </template></v-autocomplete>

                        <v-autocomplete v-if="this.userRole == 'HR Cabang' || this.userRole == 'ETD' && this.cabang.AREAID != '00'" disabled
                        :items="items_direktorat"
                        label="Direktorat"
                        v-model="direktorat"
                        item-text="NAME"
                        item-value="OMOPERATINGUNITNUMBER"
                        @change="dataBagian"
                        return-object
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.OMOPERATINGUNITNUMBER }} -
                          {{ data.item.NAME }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.OMOPERATINGUNITNUMBER }} -
                          {{ data.item.NAME }}
                        </template></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="5">
                      <v-autocomplete
                        :items="items_bagian"
                        label="Bagian"
                        v-model="bagian"
                        item-text="NAME"
                        item-value="OMOPERATINGUNITNUMBER"
                        return-object
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.OMOPERATINGUNITNUMBER }} -
                          {{ data.item.NAME }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.OMOPERATINGUNITNUMBER }} -
                          {{ data.item.NAME }}
                        </template>
                      </v-autocomplete>
                     
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3">
                      <v-autocomplete
                        :items="items_status"
                        label="Status"
                        v-model="status"
                        item-text="NAMA_STATUS"
                        item-value="NAMA_STATUS"
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <v-alert type="info">Harap Memilih 2 Tanggal</v-alert>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            prepend-icon="mdi-calendar"
                            label="Pilih Range Periode"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" range>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false"
                            >Batal</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="showDate()"
                            :loading="btnLoading"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoading"
                        @click="filterMPVC()"
                        v-show="validHRCabang || validPICHO || validETD"
                        >Filter</v-btn
                      >
                    </v-col>
                  </v-row>
                  <div class="mt-10" v-show="dataTable">
                    <v-data-table
                      :headers="headers"
                      :items="itemsTableMPVC"
                      :search="search"
                      class="elevation-1"
                    >
                    <!-- <template slot="items" slot-scope="props">
                      <tr>
                        <td>{{ props.item.NAMA }}</td>
                        <td>{{ props.item.NIK }}</td>
                        <td>{{ props.item.NO_HP }}</td>
                        <td>{{ props.item.NAMA_PT }}</td>
                        <td>{{ props.item.NAMA_CABANG }}</td>
                        <td>{{ props.item.BAGIAN }}</td>
                        <td>{{ props.item.NO_TICKET }}</td>
                        <td>{{ props.item.RENCANA }}</td>
                        <td>{{ props.item.REALISASI }}</td>
                        <td>{{ props.item.STATUS }}</td>
                        <td>{{ props.item.NIK_ATASAN }}</td>
                        <td>{{ props.item.NAMA_ATASAN }}</td>
                        <td>{{ props.item.NO_HP_ATASAN }}</td>
                        <td>{{ props.item.KETERANGAN }}</td>
                      </tr>
                    </template> -->
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-btn
                            color="success"
                            dark
                            class="mb-2"
                            @click="downloadExcel()"
                          >
                            Export Excel
                          </v-btn>

                          <!-- jarak judul -->
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <!-- search -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <!-- <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon> -->
                        <v-icon small @click="deleteItem(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                      <!-- <template v-slot:[`item.TAHAP1`]="{ item }">
                        <td class="text-xs-right">
                          {{ formatDate2(item.TAHAP1) }}
                        </td>
                      </template>
                      <template v-slot:[`item.TAHAP2`]="{ item }">
                        <td class="text-xs-right">
                          {{ formatDate2(item.TAHAP2) }}
                        </td>
                      </template> -->

                      <!-- <template v-slot:no-data>
                    <h3>No Data</h3>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                    </v-data-table>
                  </div>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Toolbar from "@/components/Toolbar.vue";
import XLSX from "xlsx";

export default {
  name: "Home",
  components: {
    Sidebar,
    Toolbar,
  },

  mounted() {
    this.dataUnit();
    this.dataStatus();
    this.userRole = sessionStorage.getItem('role');
    // console.log(this.userRole)
    // this.userPIC();
    // this.dataBagian();
  },

  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    computedDateFormatted() {
      // return this.dates.join(' ~ ');
      if (this.dates.length <= 0) {
        return "";
      } else {
        let date1 = this.formatDate(new Date(this.dates[0]));
        let date2 = this.formatDate(new Date(this.dates[1]));

        if (date2 < date1) {
          date2 = this.formatDate(new Date(this.dates[0]));
          date1 = this.formatDate(new Date(this.dates[1]));
        }
        const merge = [date1, date2];
        const temp = merge.join(" ~ ");
        return temp;
      }
    },

    validETD() {
      const userRole = sessionStorage.getItem('role');
      console.log(this.cabang.AREAID)
      if(this.cabang.AREAID != "00"){
         return this.unit != "" && 
          // this.cabang != "" && 
          this.flag_vaksin != "" &&
          // this.direktorat != "" &&
          userRole == 'ETD'
          ;
      }else{
         return this.unit != "" && 
          // this.cabang != "" && 
          this.flag_vaksin != "" &&
          // this.direktorat != "" &&
          userRole == 'ETD'
          ;
      }
     
    },

    validPICHO() {
      const userRole = sessionStorage.getItem('role');
      return this.unit != "" && 
      // this.cabang != "" && 
      this.flag_vaksin != "" &&
      // this.direktorat != "" &&
      userRole == 'PIC HO'
      ;
    },

    validHRCabang() {
      const userRole = sessionStorage.getItem('role');
      return this.unit != "" && 
      // this.cabang != "" && 
      this.flag_vaksin != "" &&
      // this.bagian != "" &&
      userRole == 'HR Cabang'
      ;
    },

  //  headers() {
  //     return [
  //       {
  //         text: "Nama Karyawan",
  //         align: "left",
  //         sortable: false,
  //         value: "NAMA",
  //         // filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'search' ].toLowerCase() ) }
  //         filter: this.nameFilter,
  //       },
  //       {
  //         text: "NIK",
  //         value: "NIK",
  //         // filter: value => {
  //         //   if (!this.calories) return true;
  //         //   return value < parseInt(this.calories);
  //         // }
  //       },
  //       {
  //         text: "NO HP",
  //         value: "NO_HP"
  //       },
  //       {
  //         text: "Unit/PT",
  //         value: "NAMA_PT"
  //       },
  //       {
  //         text: "Cabang",
  //         value: "NAMA_CABANG"
  //       },
  //       {
  //         text: "Bagian",
  //         value: "BAGIAN"
  //       },
  //       {
  //         text: "No Pendataan Vaksinasi",
  //         value: "NO_TICKET"
  //       },
  //       {
  //         text: "Rencana Vaksinasi",
  //         value: "RENCANA"
  //       },
  //       {
  //         text: "Realisasi Vaksinasi",
  //         value: "REALISASI"
  //       },
  //       {
  //         text: "Status",
  //         value: "STATUS"
  //       },
  //       {
  //         text: "NIK Atasan",
  //         value: "NIK_ATASAN"
  //       },
  //       {
  //         text: "Nama Atasan",
  //         value: "NAMA_ATASAN"
  //       },
  //       {
  //         text: "No HP Atasan",
  //         value: "NO_HP_ATASAN"
  //       },
  //       {
  //         text: "Keterangan",
  //         value: "KETERANGAN"
  //       },
  //     ];
  //   }
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    userRole: "",
    menu2: false,
    dates: [],
    btnLoading: false,
    dataTable: false,
    unit: "",
    cabang: "",
    direktorat: "",
    bagian: "",
    status: "",
    flag_vaksin: "",
    items_flag_vaksin: ['All', 'Wajib', 'Booster'],
    items_unit: [],
    items_cabang: [],
    items_direktorat: [],
    items_bagian: [],
    items_status: [],
    search: null,
    headers: [
      // {
      //   text: "No",
      //   align: "start",
      //   sortable: false,
      //   value: "name",
      // },
      { text: "Nama Karyawan", value: "NAMA" },
      { text: "NIK", value: "NIK" },
      // { text: "No HP", value: "NO_HP" },
      { text: "Unit/PT", value: "NAMA_PT" },
      { text: "Direktorat", value: "DIREKTORAT" },
      { text: "Cabang", value: "NAMA_CABANG" },
      { text: "Bagian", value: "BAGIAN" },
      { text: "Flag Vaksin", value: "FLAG_VAKSIN" },
      // { text: "No Pendataan Vaksinasi", value: "NO_TICKET" },
      // { text: "Rencana Vaksinasi", value: "RENCANA" },
      { text: "Vaksin 1", value: "TAHAP1" },
      { text: "Vaksin 2", value: "TAHAP2" },
      { text: "Vaksin 3", value: "TAHAP3" },
      { text: "Nama Vaksin Wajib", value: "WAJIB" },
      { text: "Nama Vaksin Booster", value: "BOOSTER" },
      // { text: "No ID Sertifikat", value: "NOMOR_SERTIFIKAT" },
      // { text: "Rencana Vaksinasi Tahap 2", value: "VAKSIN3" },
      // { text: "Realisasi Vaksinasi Tahap 2", value: "VAKSIN4" },
      { text: "Status", value: "STATUS" },
      // { text: "NIK Atasan", value: "NIK_ATASAN" },
      // { text: "Nama Atasan", value: "NAMA_ATASAN" },
      // { text: "No HP Atasan", value: "NO_HP_ATASAN" },
      // { text: "Keterangan", value: "KETERANGAN" },
    ],
    itemsTableMPVC: [],
    // rulesDate: [
    //   // v => this.date[0] <= this.date[1] || "Finish Date can't be earlier than Start Date"
    //     (v) =>v && this.date[1] < this.date[0] || "Finish Date can't be earlier than Start Date",
    // ]
  }),

  methods: {
    filterMPVC() {
      // console.log(this.unit.AliasUnit);
      // console.log(this.unit.NamaUnit);
      // console.log(this.cabang.AREAID);
      // console.log(this.cabang.AREANAME);
      // console.log(this.direktorat);
      // console.log(this.direktorat.NAME);
      // console.log(this.bagian);
      // console.log(this.bagian.NAME);
      // console.log(this.status);
      // console.log("awal: " +this.dates[0]);
      // console.log("akhir: " +this.dates[1]);
      let date1 = this.dates[0];
      let date2 = this.dates[1];

        if (date2 < date1) {
          date2 = this.dates[0];
          date1 = this.dates[1];
        }
      // console.log(this.dates[0]);
      // console.log(this.dates[1]);
      // console.log("awal: " +date1);
      // console.log("akhir: " +date2);
      this.btnLoading = true;
      const userRole = sessionStorage.getItem("role")
      const axios = require("axios");
      const getMPVC = {
        code: "1",
        data: {
          unit: this.unit.AliasUnit,
          cabang: this.cabang.AREAID == undefined ? null : this.cabang.AREAID,
          bagian: this.bagian.OMOPERATINGUNITNUMBER == undefined ? null : this.bagian.OMOPERATINGUNITNUMBER,
          status: this.status == "" ? null : this.status,
          periode_awal: date1 == undefined ? null : date1,
          periode_akhir: date2 == undefined ? null : date2,
          direktorat: this.direktorat.OMOPERATINGUNITNUMBER == undefined ? null : this.direktorat.OMOPERATINGUNITNUMBER,
          role: userRole,
          jenis_vaksin : null,
          flag_vaksin : this.flag_vaksin
        },
      };
      console.log(getMPVC);
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "Report", getMPVC, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.result.message);
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.itemsTableMPVC = response.data.Result;
            this.btnLoading = false;
            this.dataTable = true;
            this.getReport = this.itemsTableMPVC.map(item => {
              return {
                "Nama Karyawan" : item.NAMA,
                "NIK" : item.NIK,
                "Unit/PT" : item.NAMA_PT,
                "Direktorat" : item.DIREKTORAT,
                "Cabang" : item.NAMA_CABANG,
                "Bagian" : item.BAGIAN,
                "Flag Vaksin" : item.FLAG_VAKSIN,
                "Vaksin 1" : item.TAHAP1,
                "Vaksin 2" : item.TAHAP2,
                "Vaksin 3" : item.TAHAP3,
                "Nama Vaksin Wajib" : item.WAJIB,
                "Nama Vaksin Booster" : item.BOOSTER,
                "Status" : item.STATUS,
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
        });
    },

    formatDate(val) {
      const MyDate = new Date(val);

      const MyDateString =
        ("0" + MyDate.getDate()).slice(-2) +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        MyDate.getFullYear();
      return MyDateString;
    },

    showDate() {
      this.menu2 = false;
      // console.log(this.dates[0]);
      // console.log(this.dates[1]);
    },

    dataUnit() {
      // const getUnit = {nik: sessionStorage.getItem("nik")}
      console.log(sessionStorage.getItem("role"));
      const role = sessionStorage.getItem("role");
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const axios = require("axios");

      if (role == "ETD") {
        axios
          .get(this.$baseurl + "GetAllUnit", { withCredentials: true })
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data;
              this.items_unit = hasil;
              // console.log(hasil)
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Unit Tidak Ditemukan",
            });
          });

        //rolePIC == PIC HO 
      } else if (role == "PIC HO"){
        const getUnit = {
          code: "2",
          data: {
            NIK: sessionStorage.getItem("nik"),
          },
        };
        axios
          .post(this.$baseurl + "MasterUser", getUnit, { withCredentials: true })
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data.result;

              let hasiltest = [
                { AliasUnit: hasil[0].ID_PT, NamaUnit: hasil[0].UNIT },
              ];
              this.items_unit = hasiltest;
              //  console.log(hasiltest)
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Unit Tidak Ditemukan",
            });
          });
          //rolePIC == HR Cabang
      } else{
        const getUnitHR = {
          code: "9",
          data: {
            NIK: sessionStorage.getItem("nik"),
          },
        };
        axios
          .post(this.$baseurl + "MasterUser", getUnitHR, { withCredentials: true })
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data.result;

              // let hasiltest = [
              //   { AliasUnit: hasil[0].ID_PT, NamaUnit: hasil[0].UNIT },
              // ];
              let hasiltest = hasil.map((item) => {
               return { "AliasUnit" : item.ID_PT,
                          "NamaUnit" : item.UNIT } });
              this.items_unit = hasiltest;
              //  console.log(hasiltest)
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Unit Tidak Ditemukan",
            });
          });
      }
    },

    dataCabang() {
      this.cabang = "";
      this.direktorat = "";
      this.bagian = "";
      const getCabang = {
        alias_unit: this.unit.AliasUnit,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const role = sessionStorage.getItem("role");
      const axios = require("axios");

      if (role == "ETD") {
        axios
          .post(this.$baseurl + "GetCabangByUnit", getCabang, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
               // console.log(response.data.GetAllUnitResult);
                const hasil = response.data;
                this.items_cabang = hasil;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Cabang Tidak Ditemukan",
            });
          });
      } else if (role == "PIC HO") {
        // PIC HO
        const getCabangHO = {
          code: "2",
          data: {
            NIK: sessionStorage.getItem("nik"),
          },
        };
        axios
          .post(this.$baseurl + "MasterUser", getCabangHO, { withCredentials: true })
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data.result;
              //  console.log(response.data)
              let hasiltest = [
                { AREAID: hasil[0].ID_CABANG, AREANAME: hasil[0].CABANG },
              ];
              this.items_cabang = hasiltest;
              //  console.log(hasiltest)
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Cabang Tidak Ditemukan",
            });
          });
      } else{
        //HR Cabang
        const getCabangHR = {
          code: "6",
          data: {
            NIK: sessionStorage.getItem("nik"),
            ROLE: sessionStorage.getItem("role"),
            ID_PT: this.unit.AliasUnit
          },
        };
        axios
          .post(this.$baseurl + "MasterUser", getCabangHR, { withCredentials: true })
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data.result;
              //  console.log(response.data)
              let hasiltest = hasil.map((item) => {
               return { "AREAID" : item.ID_CABANG,
                          "AREANAME" : item.CABANG } });
              // this.items_bagian = hasiltest;
              this.items_cabang = hasiltest;
              //  console.log(hasiltest)
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Cabang Tidak Ditemukan",
            });
          });
      }
    },

    dataDirektorat() {
      this.direktorat = "";
      this.bagian = "";
      this.dataBagian();
      const getDirektorat = {
        // level: "3",
        id_unit: this.unit.AliasUnit,
        id_cabang: this.cabang.AREAID,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const role = sessionStorage.getItem("role");
      const axios = require("axios");

      if (role == "ETD") {
        axios
          .post(
            this.$baseurl + "GetDirektoratByUnitCabang",
            getDirektorat,
            { withCredentials: true }
          )
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data;
              this.items_direktorat = hasil;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Direktorat Tidak Ditemukan",
            });
          });
      } else if(role == "PIC HO") {
        const getBagianHO = {
          code: "7",
          data: {
            NIK: sessionStorage.getItem("nik"),
            ROLE: sessionStorage.getItem("role"),
          },
        };
        axios
          .post(this.$baseurl + "MasterUser", getBagianHO, {
            withCredentials: true,
          })
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data.result;
              // console.log(hasil);
              let hasiltest = hasil.map((item) => item.ID_BAGIAN);
              // console.log(hasiltest);
              for (var i = 0; i < hasiltest.length; i++) {
                hasiltest[i] = hasiltest[i].substring(0, 6);
              }
              // console.log(hasiltest);
              const getDirektoratHO = {
                id_direktorat: ";" + hasiltest + ";",
                id_cabang: "00",
              };
              axios
                .post(this.$baseurl + "GetDirektorat", getDirektoratHO, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                    this.$swal({
                      icon: "info",
                      title: "Info!",
                      text: "Session Anda Telah Habis",
                    }).then(() => {
                      sessionStorage.clear();
                      this.$router.push({ name: "Login" });
                    });
                  } else {
                    const hasil = response.data;
                    // console.log(hasil)
                    this.items_direktorat = hasil;
                  }
                });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Direktorat Tidak Ditemukan",
            });
          });
      } else{
        this.dataBagian();
      }
    },

    dataBagian() {
      this.bagian = "";
      const getBagian = {
        id: this.direktorat.OMOPERATINGUNITNUMBER
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const axios = require("axios");
      const role = sessionStorage.getItem("role");
      // console.log(this.cabang.AREAID)
      if (role == "ETD" &&  this.cabang.AREAID == '00') {
        axios
          .post(
            this.$baseurl + "GetDepartemenByDirektorat",
            // "https://ms.hrindomaret.com/api/v1/DVC/Utility/Company/GetDepartemenByDirektorat",
            getBagian,
            { withCredentials: true }
          )
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data;
              this.items_bagian = hasil;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Bagian Tidak Ditemukan",
            });
          });
      
    }else if(role == "ETD" &&  this.cabang.AREAID != '00'){
      const getDeptbyCabang = {
        id_unit: this.unit.AliasUnit,
        id_cabang: this.cabang.AREAID
      };
      console.log(getDeptbyCabang)
      axios
          .post(
            this.$baseurl + "GetDepartemenByUnitCabang",
            // "https://ms.hrindomaret.com/api/v1/DVC/Utility/Company/GetDepartemenByDirektorat",
            getDeptbyCabang,
            { withCredentials: true }
          )
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data;
              this.items_bagian = hasil;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Bagian Tidak Ditemukan",
            });
          });

    }else if(role == "PIC HO"){
        const getBagianHO = {
          code: "7",
          data: {
            NIK: sessionStorage.getItem('nik'),
            ROLE: role
          }
        }
        axios
          .post(
            this.$baseurl + "MasterUser",
            getBagianHO,
            { withCredentials: true }
          )
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data.result;
              let hasiltest = hasil.map((item) => {
               return { "OMOPERATINGUNITNUMBER" : item.ID_BAGIAN,
                          "NAME" : item.BAGIAN } });
              this.items_bagian = hasiltest;

            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Bagian Tidak Ditemukan",
            });
          });
      } else{
        //HR Cabang
        const getBagianHR = {
          id_unit: this.unit.AliasUnit,
          id_cabang: this.cabang.AREAID
        }
        axios
          .post(
            this.$baseurl + "GetDepartemenByUnitCabang",
            getBagianHR,
            { withCredentials: true }
          )
          .then((response) => {
            // console.log(response.data.GetAllUnitResult);
            if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
              this.$swal({
                icon: "info",
                title: "Info!",
                text: "Session Anda Telah Habis",
              }).then(() => {
                sessionStorage.clear();
                this.$router.push({ name: "Login" });
              });
            } else {
              const hasil = response.data;
              this.items_bagian = hasil;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Bagian Tidak Ditemukan",
            });
          });
      }
    },

    dataStatus() {
      // const getUnit = {nik: sessionStorage.getItem("nik")}
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const getStatus = {
        code: "5",
      };
      const axios = require("axios");
      axios
        .post(this.$baseurl + "MasterStatus", getStatus, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.GetAllUnitResult);
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            const hasil = response.data.message;
            this.items_status = hasil;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Status Tidak Ditemukan",
          });
        });
    },

    nameFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.search) {
          return true;
        }
        // Check if the current loop value (The dessert name)
        // partially contains the searched word.
        return value.toLowerCase().includes(this.search.toLowerCase());
      },

      formatDate2(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    downloadExcel() {
      // var headings = [
      //   [
      //     "Nama Karyawan",
      //     "NIK",
      //     "Unit/PT",
      //     "Direktorat",
      //     "Cabang",
      //     "Bagian",
      //     "Tahap 1",
      //     "Tahap 2",
      //     "Nama Vaksin",
      //     "No ID Sertifikat",
      //     "Status",
      //   ],
      // ];

      const wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.getReport, {
        origin: "A2",
        // skipHeader: true,
      });
      // XLSX.utils.sheet_add_aoa(ws, headings); //heading: array of arrays

      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, "Report MPVC.xlsx");
    },
  },
};
</script>
