<template>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <span class="hidden-sm-and-down">Pendataan Vaksinasi Covid-19</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="body-2"
        >Logout</v-toolbar-title
      >
      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
export default {
    
  computed: {
    ...mapGetters({
      getDrawer: "drawer",
      user: "user",
      // hasAllAccess: "hasAllAccess",
    }),
    drawer: {
      get() {
        return this.getDrawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },
  },

  methods: {
    ...mapActions({
      setDrawer: "setDrawer",
      removeUser: "removeUser",
    }),

    logout() {
      this.removeUser();
      sessionStorage.clear();
      // sessionStorage.removeItem();
      localStorage.clear();
      // this.$store.dispatch('clearState');
      this.$router.push({ name: "Login" });
    },
  },
}
</script>