import Vue from "vue";
import Vuex from "vuex";
import drawer from "./drawer";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // isLoading: false,
    // isLogin: false,
    // token: null,
    // nik: null
    // user: null,
  },
  mutations: {
    // resetState(state) {
    //   state.isLoading = false;
    //   state.isLogin = false;
    //   state.token = null;
    //   state.nik = null;
    //   // state.user = null;
    // },
  },
  actions: {
    // clearState({ commit }) {
    //   commit("resetState");
    // },
  },
  modules: {
    // login,
    user,
    drawer,
  },
});
