<template>
  <div>
    <Sidebar />
    <Toolbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="image/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Master User
              </h3>

              <div class="pa-1 mx-4">
                <v-data-table
                  :headers="headers"
                  :items="itemsTableUser"
                  :search="search"
                  sort-by="nama"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New User
                          </v-btn>
                        </template>
                        <v-card elevation="10">
                          <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-row>
                                  <v-col cols="12" sm="9" md="9">
                                    <v-text-field
                                      v-model="NIK_NamaKaryawan"
                                      label="NIK / Nama Karyawan (Min 6 Karakter)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      @click="listKaryawan"
                                      :loading="btnLoading"
                                    >
                                      Cari
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="chooseKar.NIK"
                                      label="NIK"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="chooseKar.NAMA"
                                      label="Nama"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" sm="4" md="4">
                                    <v-card-text>
                                      <h2
                                        class="subtitle-1 font-weight-regular text-justify"
                                      >
                                        Pilih Role PIC
                                      </h2>
                                    </v-card-text>
                                  </v-col>
                                  <v-col cols="12" sm="8" md="8">
                                    <v-radio-group
                                      v-model="rolePIC"
                                      required
                                      row
                                    >
                                      <v-radio
                                        v-for="item in itemJawab"
                                        :key="item.id"
                                        :label="`${item.name}`"
                                        :value="item.id"
                                        @click="
                                          (rolePIC == 'ETD' &&
                                            clearBagianCabang() &&
                                            validETD()) ||
                                            (rolePIC == 'PIC HO' &&
                                              clearCabang() &&
                                              validPICHO()) ||
                                            (rolePIC == 'HR Cabang' &&
                                              clearBagian() &&
                                              validCabang())
                                        "
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-col>
                                </v-row>

                                <v-col cols="12" sm="9" md="9">
                                  <v-autocomplete
                                    v-show="
                                      rolePIC == 'HR Cabang' ||
                                        rolePIC == 'PIC HO'
                                    "
                                    :items="items_unit"
                                    label="Unit"
                                    v-model="unit"
                                    item-text="NamaUnit"
                                    item-value="AliasUnit"
                                    @change="dataCabang"
                                    return-object
                                  ></v-autocomplete>
                                  <v-autocomplete
                                    v-show="rolePIC == 'HR Cabang'"
                                    :items="items_cabang"
                                    label="Cabang"
                                    v-model="cabang"
                                    multiple
                                    small-chips
                                    item-text="AREANAME"
                                    item-value="AREAID"
                                    return-object
                                    clearable
                                    deletable-chips
                                  ></v-autocomplete>
                                  <v-autocomplete
                                    v-show="rolePIC == 'PIC HO'"
                                    :items="items_bagian"
                                    label="Bagian"
                                    v-model="bagian"
                                    multiple
                                    small-chips
                                    item-text="NAME"
                                    item-value="OMOPERATINGUNITNUMBER"
                                    return-object
                                    clearable
                                    deletable-chips
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="save"
                              v-show="validETD || validPICHO || validCabang"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog v-model="dialogKary" max-width="500px">
                        <v-card>
                          <v-card-title class="headline"
                            >List Karyawan</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-data-table
                              :headers="headers_list"
                              :items="items_list"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.detail`]="{ item }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  @click="pilihKary(item)"
                                >
                                  Pilih
                                </v-btn>
                              </template>
                            </v-data-table>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <!-- jarak judul -->
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- search -->
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon> -->
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>

                  <!-- <template v-slot:no-data>
                    <h3>No Data</h3>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Toolbar from "@/components/Toolbar.vue";
const axios = require("axios");
export default {
  name: "Home",
  components: {
    Sidebar,
    Toolbar,
  },

  mounted() {
    // this.name = this.user.NAMA;
    // this.nik = this.user.NIK;
    // this.dataUnit()
    // this.showUserRole();
    this.dataKaryawan();
    this.dataUnit();
    this.dataBagian();
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    modelKaryawan: null,
    cariKaryawan: null,
    NIK_NamaKaryawan: "",

    dialog: false,
    dialogKary: false,
    // dialogDelete: false,
    model: null,
    search: null,
    btnLoading: false,
    // search: "",
    nik: "",
    nama: "",
    rolePIC: "",
    id_unit: "",
    id_bagian: [],
    id_cabang: [],
    unit: "",
    bagian: [],
    cabang: [],
    code: "",

    karyawan: [],

    headers: [
      {
        text: "NIK",
        align: "start",
        sortable: true,
        value: "NIK",
      },
      // { text: "NIK", value: "NIK" },
      { text: "Nama", value: "NAMA" },
      { text: "Role", value: "ROLE" },
      { text: "Cabang", value: "CABANG" },
      { text: "Bagian", value: "BAGIAN" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headers_list: [
      { text: "NIK", value: "NIK" },
      { text: "NAMA", value: "NAMA" },
      { text: "Actions", value: "detail", sortable: false },
    ],

    loadingTable: false,

    showDepartement: false,
    itemJawab: [
      {
        id: "ETD",
        name: "ETD",
      },
      {
        id: "PIC HO",
        name: "PIC HO",
      },
      {
        id: "HR Cabang",
        name: "HR Cabang",
      },
    ],
    itemsTableUser: [],
    items_list: [],
    items_unit: [],
    items_bagian: [],
    items_cabang: [],
    editedIndex: -1,
    editedItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },

    chooseKar: {
      nik: "",
      nama: "",
    },
    defaultItem: {
      id_user: "",
      nik: "",
      nama: "",
      role: "",
      bagian: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    fields() {
      if (!this.modelKaryawan) return [];

      return Object.keys(this.modelKaryawan).map((key) => {
        return {
          key,
          value: this.modelKaryawan[key] || "n/a",
        };
      });
    },
    itemsKaryawan() {
      return this.entries.map((entry) => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },

    validETD() {
      return this.chooseKar.NIK && this.chooseKar.NAMA && this.rolePIC == "ETD";
    },
    validPICHO() {
      return (
        this.chooseKar.NIK &&
        this.chooseKar.NAMA &&
        this.rolePIC == "PIC HO" &&
        this.unit != "" &&
        this.bagian.length != 0
      );
    },
    validCabang() {
      return (
        this.chooseKar.NIK &&
        this.chooseKar.NAMA &&
        this.rolePIC == "HR Cabang" &&
        this.unit != "" &&
        this.cabang.length != 0
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      const code = {
        code: 5,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const axios = require("axios");
      axios
        .post(this.$baseurl + "MasterUser", code, { withCredentials: true })
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // console.log(result.result[0]);
            this.itemsTableUser = result.result;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menghapus User",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.editedIndex = this.itemsTableUser.indexOf(item);
          this.editedItem = Object.assign({}, item);
          console.log(this.editedItem);
          const deleteUser = {
            code: "4",
            data: {
              ID: this.editedItem.ID_USER,
            },
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   JWT: varToken,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          axios
            .post(this.$baseurl + "MasterUser", deleteUser, { withCredentials: true })
            .then((response) => {
              // console.log(response.data.result.message);
              if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.result.message == "OK") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: "User Telah Dihapus",
                });
                this.initialize();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menghapus",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });

      // this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.NIK_NamaKaryawan = "";
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.rolePIC = "";
      this.unit = "";
      this.cabang = [];
      this.bagian = [];
      // this.clearBagianCabang;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },

    save() {
      // if (this.editedIndex > -1) {
        //edit
        // Object.assign(this.desserts[this.editedIndex], this.editedItem);
      // } else {
        //new
        // this.desserts.push(this.editedItem);
        // console.log(this.chooseKar.NIK);
        // console.log(this.chooseKar.NAMA);
        // console.log(this.rolePIC);
        // console.log(this.unit);
        // console.log(this.cabang);
        // console.log(this.bagian);

        const NIK = this.chooseKar.NIK;
        const NAMA = this.chooseKar.NAMA;
        const rolePIC = this.rolePIC;
        const id_unit = this.unit.AliasUnit;
        const unit = this.unit.NamaUnit;
        // const id_cabang = this.cabang.AREAID;
        // const cabang = this.cabang;
        // const bagian = this.bagian;
        // const bagian = this.bagian.NAME;
        let temp_id_bagian = this.bagian.map(item => item.OMOPERATINGUNITNUMBER)
        let temp_bagian = this.bagian.map(item => item.NAME)
        let temp_id_cabang = this.cabang.map(item => item.AREAID)
        let temp_cabang = this.cabang.map(item => item.AREANAME)

        // let tempBagian = this.bagian.map(item => item.OMOPERATINGUNITNUMBER)
        // const tempBagian = this.bagian.map((item) => item.OMOPERATINGUNITNUMBER);
        // console.log(tempBagian)
        const NIK_LOGIN = sessionStorage.getItem("nik");
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Menambah User",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const addUserETD = {
              code: "1",
              data: {
                NIK: NIK,
                NAMA: NAMA,
                ROLE: rolePIC,
                ID_UNIT: "",
                ID_CABANG: "",
                ID_BAGIAN: "",
                UNIT: "",
                CABANG: "",
                BAGIAN: "",
                Creator_editor: NIK_LOGIN,
              },
            };

            const addUserPICHO = {
              code: "1",
              data: {
                NIK: NIK,
                NAMA: NAMA,
                ROLE: rolePIC,
                ID_UNIT: id_unit,
                ID_CABANG: "00",
                ID_BAGIAN: ";" + temp_id_bagian + ";",
                UNIT: unit,
                CABANG: "-",
                BAGIAN: ";" + temp_bagian + ";",
                Creator_editor: NIK_LOGIN,
              },
            };
            const addUserHRCabang = {
              code: "1",
              data: {
                NIK: NIK,
                NAMA: NAMA,
                ROLE: rolePIC,
                ID_UNIT: id_unit,
                ID_CABANG: ";" + temp_id_cabang + ";",
                ID_BAGIAN: "",
                UNIT: unit,
                CABANG: ";" + temp_cabang + ";",
                BAGIAN: "",
                Creator_editor: NIK_LOGIN,
              },
            };
            console.log(addUserHRCabang)
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   JWT: varToken,
            //   // NIK: sessionStorage.getItem("nik"),
            // };
            const axios = require("axios");
            // console.log(this.rolePIC);
            if (rolePIC == "ETD") {
              axios
                .post(this.$baseurl + "MasterUser", addUserETD, {
                  withCredentials: true,
                })
                .then((response) => {
                  console.log(response.data.result.message);
                  if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                    this.$swal({
                      icon: "info",
                      title: "Info!",
                      text: "Session Anda Telah Habis",
                    }).then(() => {
                      sessionStorage.clear();
                      this.$router.push({ name: "Login" });
                    });
                  } else if (response.data.result.message == "OK") {
                    this.$swal({
                      icon: "success",
                      title: "Berhasil",
                      text: "User Telah Ditambahkan",
                    }).then(() => {
                      this.initialize();
                      // window.location.reload();
                      this.clearForm();
                    });
                  } else {
                    this.$swal({
                      icon: "error",
                      title: "Maaf!",
                      text:
                        "Gagal Menambahkan, " + response.data.result.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menambahkan",
                  });
                });
            } else if (rolePIC == "PIC HO") {
              console.log(addUserPICHO)
              axios
                .post(this.$baseurl + "MasterUser", addUserPICHO, {
                  withCredentials: true,
                })
                .then((response) => {
                  console.log(response.data.result.message);
                  if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                    this.$swal({
                      icon: "info",
                      title: "Info!",
                      text: "Session Anda Telah Habis",
                    }).then(() => {
                      sessionStorage.clear();
                      this.$router.push({ name: "Login" });
                    });
                  } else if (response.data.result.message == "OK") {
                    this.$swal({
                      icon: "success",
                      title: "Berhasil",
                      text: "User Telah Ditambahkan",
                    }).then(() => {
                      this.initialize();
                      // window.location.reload();
                      this.clearForm();
                    });
                  } else {
                    this.$swal({
                      icon: "error",
                      title: "Maaf!",
                      text:
                        "Gagal Menambahkan, " + response.data.result.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menambahkan",
                  });
                });
            } else {
              axios
                .post(this.$baseurl + "MasterUser", addUserHRCabang, {
                  withCredentials: true,
                })
                .then((response) => {
                  console.log(response.data.result.message);
                  if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                    this.$swal({
                      icon: "info",
                      title: "Info!",
                      text: "Session Anda Telah Habis",
                    }).then(() => {
                      sessionStorage.clear();
                      this.$router.push({ name: "Login" });
                    });
                  } else if (response.data.result.message == "OK") {
                    this.$swal({
                      icon: "success",
                      title: "Berhasil",
                      text: "User Telah Ditambahkan",
                    }).then(() => {
                      this.initialize();
                      // window.location.reload();
                      this.clearForm();
                    });
                  } else {
                    this.$swal({
                      icon: "error",
                      title: "Maaf!",
                      text:
                        "Gagal Menambahkan, " + response.data.result.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menambahkan",
                  });
                });
            }
          }
        });

        // if (rolePIC == "PIC HO") {
        //   this.$swal({
        //     icon: "info",
        //     title: "Apakah Anda Yakin?",
        //     text: "Untuk Menambah User PIC HO",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     cancelButtonText: `Cancel`,
        //     confirmButtonText: `Yes`,
        //   }).then((response) => {
        //     if (response.isConfirmed) {
        //       const addUserPICHO = {
        //         code: "1",
        //         data: {
        //           NIK: this.chooseKar.NIK,
        //           NAMA: this.chooseKar.NAMA,
        //           ROLE: rolePIC,
        //           UNIT: this.unit,
        //           CABANG: "",
        //           BAGIAN: ";" + this.bagian + ";",
        //           Creator_editor: NIK,
        //         },
        //       };

        //       const varToken = sessionStorage.getItem("token");
        //       const headers = {
        //         JWT: varToken,
        //         NIK: sessionStorage.getItem("nik"),
        //       };
        //       const axios = require("axios");
        //       console.log(this.rolePIC);

        //       axios
        //         .post(this.$baseurl + "api/v1/MasterUser", addUserPICHO, {
        //           headers,
        //         })
        //         .then((response) => {
        //           console.log(response.data.result.message);
        //           if (response.data.Message) {
        //             this.$swal({
        //               icon: "info",
        //               title: "Info!",
        //               text: "Session Anda Telah Habis",
        //             }).then(() => {
        //               sessionStorage.clear();
        //               this.$router.push({ name: "Login" });
        //             });
        //           } else if (response.data.result.message == "OK") {
        //             this.$swal({
        //               icon: "success",
        //               title: "Berhasil",
        //               text: "User Telah Ditambahkan",
        //             }).then(() => {
        //               this.initialize();
        //               // window.location.reload();
        //               this.clearForm();
        //             });
        //           } else {
        //             this.$swal({
        //               icon: "error",
        //               title: "Maaf!",
        //               text:
        //                 "Gagal Menambahkan, " + response.data.result.message,
        //             });
        //           }
        //         })
        //         .catch((error) => {
        //           console.log(error);
        //           this.$swal({
        //             icon: "error",
        //             title: "Maaf!",
        //             text: "Gagal Menambahkan",
        //           });
        //         });
        //     }
        //   });
        // } else if(rolePIC == "HR Cabang"){
        //   this.$swal({
        //     icon: "info",
        //     title: "Apakah Anda Yakin?",
        //     text: "Untuk Menambah User HR Cabang",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     cancelButtonText: `Cancel`,
        //     confirmButtonText: `Yes`,
        //   }).then((response) => {
        //     if (response.isConfirmed) {
        //       const addUserCabang = {
        //         code: "1",
        //         data: {
        //           NIK: this.chooseKar.NIK,
        //           NAMA: this.chooseKar.NAMA,
        //           ROLE: rolePIC,
        //           UNIT: this.unit,
        //           CABANG: ";" + this.cabang + ";",
        //           BAGIAN: "",
        //           Creator_editor: NIK,
        //         },
        //       };

        //       const varToken = sessionStorage.getItem("token");
        //       const headers = {
        //         JWT: varToken,
        //         NIK: sessionStorage.getItem("nik"),
        //       };
        //       const axios = require("axios");
        //       console.log(this.rolePIC);

        //       axios
        //         .post(this.$baseurl + "api/v1/MasterUser", addUserCabang, {
        //           headers,
        //         })
        //         .then((response) => {
        //           console.log(response.data.result.message);
        //           if (response.data.Message) {
        //             this.$swal({
        //               icon: "info",
        //               title: "Info!",
        //               text: "Session Anda Telah Habis",
        //             }).then(() => {
        //               sessionStorage.clear();
        //               this.$router.push({ name: "Login" });
        //             });
        //           } else if (response.data.result.message == "OK") {
        //             this.$swal({
        //               icon: "success",
        //               title: "Berhasil",
        //               text: "User Telah Ditambahkan",
        //             }).then(() => {
        //               this.initialize();
        //               // window.location.reload();
        //               this.clearForm();
        //             });
        //           } else {
        //             this.$swal({
        //               icon: "error",
        //               title: "Maaf!",
        //               text:
        //                 "Gagal Menambahkan, " + response.data.result.message,
        //             });
        //           }
        //         })
        //         .catch((error) => {
        //           console.log(error);
        //           this.$swal({
        //             icon: "error",
        //             title: "Maaf!",
        //             text: "Gagal Menambahkan",
        //           });
        //         });
        //     }
        //   });
        // } else{
        //   this.$swal({
        //     icon: "info",
        //     title: "Apakah Anda Yakin?",
        //     text: "Untuk Menambah User ETD",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     cancelButtonText: `Cancel`,
        //     confirmButtonText: `Yes`,
        //   }).then((response) => {
        //     if (response.isConfirmed) {
        //       // console.log(this.rolePIC)
        //       const addUserETD = {
        //         code: "1",
        //         data: {
        //           NIK: this.chooseKar.NIK,
        //           NAMA: this.chooseKar.NAMA,
        //           ROLE: rolePIC,
        //           UNIT: "",
        //           CABANG: "",
        //           BAGIAN: "",
        //           Creator_editor: NIK,
        //         },
        //       };

        //       const varToken = sessionStorage.getItem("token");
        //       const headers = {
        //         JWT: varToken,
        //         NIK: sessionStorage.getItem("nik"),
        //       };
        //       const axios = require("axios");
        //       console.log(this.rolePIC);

        //       axios
        //         .post(this.$baseurl + "api/v1/MasterUser", addUserETD, {
        //           headers,
        //         })
        //         .then((response) => {
        //           console.log(response.data.result.message);
        //           if (response.data.Message) {
        //             this.$swal({
        //               icon: "info",
        //               title: "Info!",
        //               text: "Session Anda Telah Habis",
        //             }).then(() => {
        //               sessionStorage.clear();
        //               this.$router.push({ name: "Login" });
        //             });
        //           } else if (response.data.result.message == "OK") {
        //             this.$swal({
        //               icon: "success",
        //               title: "Berhasil",
        //               text: "User Telah Ditambahkan",
        //             }).then(() => {
        //               this.initialize();
        //               // window.location.reload();
        //               this.clearForm();
        //             });
        //           } else {
        //             this.$swal({
        //               icon: "error",
        //               title: "Maaf!",
        //               text:
        //                 "Gagal Menambahkan, " + response.data.result.message,
        //             });
        //           }
        //         })
        //         .catch((error) => {
        //           console.log(error);
        //           this.$swal({
        //             icon: "error",
        //             title: "Maaf!",
        //             text: "Gagal Menambahkan",
        //           });
        //         });
        //     }
        //   });
        // }
      // }
      this.close();
    },

    dataKaryawan() {
      // axios
      //   .post("https://hrindomaret.com/api/covidform/getUserHO", {})
      //   .then((response) => {
      //     this.karyawan = response.data;
      //     console.log(this.karyawan);
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     alert("Tidak Dapat Mengambil Karyawan");
      //   });
    },

    listKaryawan() {
      const searchKary = {
        code: 10,
        data:{
          NIK_Nama: this.NIK_NamaKaryawan
        }
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      this.btnLoading = true;
      axios
        .post(this.$baseurl + "MasterUser", searchKary, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.SearchWorkerResult.length);
          // const result = response.data;

          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else if (response.data.result.length > 0) {
              if(this.NIK_NamaKaryawan.length >= 6){
                this.items_list = response.data.result;
                this.btnLoading = false;
                this.dialogKary = true;
              }else{
                this.btnLoading = false;
                this.$swal({
                icon: "warning",
                title: "Perhatian!",
                text: "Min 6 Karakter Input",
              });
            }

          } else {
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Karyawan Tidak Ditemukan",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Karyawan Tidak Ditemukan",
          });
        });
    },

    pilihKary(item) {
      this.chooseKar = Object.assign({}, item);
      console.log(this.chooseKar);
      // this.NIK_NamaKaryawan = this.chooseKar.nik;
      this.dialogKary = false;
      this.NIK_NamaKaryawan = "";
    },

    dataUnit() {
      // const getUnit = {nik: sessionStorage.getItem("nik")}
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      //  const getUnit = {
      //   data: {
      //     code: 1
      //   }
      // };
      axios
        .get(this.$baseurl + "GetAllUnit", { withCredentials: true })
        .then((response) => {
          // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          this.items_unit = hasil;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Unit Tidak Ditemukan",
          });
        });
    },

    dataCabang() {
      const getCabang = {
        alias_unit: this.unit.AliasUnit,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "GetCabangByUnit", getCabang, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.GetAllUnitResult);
          const hasil = response.data;
          this.items_cabang = hasil;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Cabang Tidak Ditemukan",
          });
        });
    },

    dataBagian() {
      const getBagian = {
        level: "5,6",
        dept_area: "00",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(
          this.$baseurl + "GetDepartemenByLevelDeptArea",
          getBagian,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          const hasil = JSON.parse(response.data.UnitCabangResult);
          this.items_bagian = hasil;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Bagian Tidak Ditemukan",
          });
        });
    },

    clearBagianCabang() {
      this.unit = "";
      this.bagian = [];
      this.cabang = [];
    },

    clearBagian() {
      this.unit = "";
      this.bagian = [];
    },

    clearCabang() {
      this.unit = "";
      this.cabang = [];
    },

    clearForm() {
      this.chooseKar.NIK = "";
      this.chooseKar.NAMA = "";
      this.rolePIC = "";
      this.unit = "";
      this.cabang = [];
      this.bagian = [];
    },
  },
};
</script>
