<template>
  <div class="laporan">
    <Sidebar />
    <Toolbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="image/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Rekap Pendataan Vaksinasi Covid-19
              </h3>

              <!-- <v-alert class="mx-4" type="info">
               <div v-show="this.userRole == 'ETD'">
                  Mohon memilih Unit, Cabang, dan Direktorat untuk melihat
                  <i>report</i> karyawan Head Office
                </div>
                <div v-show="this.userRole == 'ETD'">
                  Mohon memilih Unit dan Cabang untuk melihat
                  <i>report</i> karyawan selain Head Office
                </div>
                <div v-show="this.userRole == 'PIC HO'">
                  Mohon memilih Unit, Cabang, dan Direktorat untuk melihat
                  <i>report</i>
                </div>
                <div v-show="this.userRole == 'HR Cabang'">
                  Mohon memilih Unit dan Cabang untuk melihat
                  <i>report</i>
                </div>
              </v-alert> -->

              <form class="pa-4 mx-3">
                <div class="my-2">
                  <v-row align="center">
                    <!-- <v-col class="d-flex" cols="12" sm="2">
                      Pilih Report
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="1">
                      :
                    </v-col> -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-autocomplete
                        :items="items_report"
                        label="Pilihan Report"
                        v-model="report"
                        item-text="Report"
                        item-value="ID_Report"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="2">
                      Vaksinasi Ke :
                    </v-col>
                    <!-- <v-col class="d-flex" cols="12" sm="1">
                      :
                    </v-col> -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group v-model="vaksinasi_ke" row>
                        <v-radio label="1" value="1"></v-radio>
                        <v-radio label="2" value="2"></v-radio>
                        <v-radio label="3" value="3"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <!-- <v-row align="center">
                    
                  </v-row> -->
                  <v-row align="center">
                    <!-- <v-col class="d-flex" cols="12" sm="2">
                      Periode
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="1">
                      :
                    </v-col> -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            prepend-icon="mdi-calendar"
                            label="Periode"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          :max="nowDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  
                  <v-row> </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoading"
                        @click="filterRPV()"
                        v-show="validRPV"
                        >Filter</v-btn
                      >
                    </v-col>
                  </v-row>
                  <div class="mt-10" v-show="dataTable">
                    <template>
                      <v-data-table
                        :headers="headers"
                        :items="itemsTableRPV"
                        :search="search"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-btn
                              color="success"
                              dark
                              class="mb-2"
                              @click="downloadExcel()"
                            >
                              Export Excel
                            </v-btn>

                            <!-- jarak judul -->
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <!-- search -->
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-toolbar>
                        </template>
                      </v-data-table>
                    </template>
                  </div>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Toolbar from "@/components/Toolbar.vue";
import XLSX from "xlsx";

export default {
  name: "Home",
  components: {
    Sidebar,
    Toolbar,
  },

  mounted() {
    // this.dataCabang();
    // this.currentDate();
    // console.log(this.currentDate);
    // this.dataUnit();
    // this.dataStatus();
    this.userRole = sessionStorage.getItem("role");
    // this.dataBagian();
  },

  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    validETD() {
      const userRole = sessionStorage.getItem("role");
      if (this.cabang.AREAID != "00") {
        return (
          this.unit != "" &&
          this.cabang != "" &&
          // this.direktorat != "" &&
          userRole == "ETD"
        );
      } else {
        return (
          this.unit != "" &&
          this.cabang != "" &&
          this.direktorat != "" &&
          userRole == "ETD"
        );
      }
    },

    validPICHO() {
      const userRole = sessionStorage.getItem("role");
      return (
        this.unit != "" &&
        this.cabang != "" &&
        this.direktorat != "" &&
        userRole == "PIC HO"
      );
    },

    validHRCabang() {
      const userRole = sessionStorage.getItem("role");
      return (
        this.unit != "" &&
        this.cabang != "" &&
        // this.bagian != "" &&
        userRole == "HR Cabang"
      );
    },

    validRPV() {
      // const userRole = sessionStorage.getItem("role");
      return this.report != "" && this.vaksinasi_ke != "" && this.date != null;
    },

    headers() {
      //set having unique values
      let s = new Set();
      var f;

      this.itemsTableRPV.forEach((item) => {
        for (f in item) {
          //adding an existing value doesn't override the old one
          s.add(f);
        }
      });
      //respect the headers format required by Vuetify which
      // should has at least two fields (text, value)
      return Array.from(s).map((a) => {
        return {
          text: a.toUpperCase(),
          value: a,
        };
      });
    },
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    nowDate: new Date().toISOString().slice(0,10),
    userRole: "",
    menu2: false,
    dates: [],
    date: null,
    btnLoading: false,
    dataTable: false,
    unit: "",
    cabang: "",
    report: "",
    vaksinasi_ke: "",
    direktorat: "",
    bagian: "",
    status: "",
    // departemen: "",
    items_unit: [],
    items_cabang: [],
    items_report: [
      // { ID_Report: "1", Report: "Report Karyawan HO PIK" },
      // { ID_Report: "2", Report: "Report Karyawan HO Non PIK" },
      { ID_Report: "3", Report: "Report Karyawan Cabang" },
      // { ID_Report: "4", Report: "Report Karyawan HO Lokasi Kerja di Cabang" },
      // { ID_Report: "5", Report: "Report Karyawan Others Unit" },
    ],
    items_departemen: [],
    items_direktorat: [],
    items_bagian: [],
    items_status: [],
    search: null,
    // headers: [
    //   // {
    //   //   text: "No",
    //   //   align: "start",
    //   //   sortable: false,
    //   //   value: "name",
    //   // },
    //   { text: "Unit/PT", value: "PT" },
    //   { text: "Cabang", value: "Lokasi" },
    //   { text: "Direktorat", value: "Direktorat" },
    //   { text: "Departemen", value: "Departemen" },
    //   // { text: "Bagian", value: "BAGIAN" },
    //   { text: "Jumlah Karyawan", value: "JUMLAH_KARYAWAN" },
    //   { text: "Jumlah Karyawan Success", value: "JUMLAH_SUCCESS" },
    //   { text: "% Success", value: "PERSENTASE_SUCCESS" },
    //   { text: "Jumlah Karyawan Outstanding", value: "JUMLAH_OUTSTANDING" },
    //   { text: "% Outstanding", value: "PERSENTASE_OUTSTANDING" },
    //   { text: "Jumlah Karyawan Pending", value: "JUMLAH_PENDING" },
    //   { text: "% Pending", value: "PERSENTASE_PENDING" },
    //   { text: "Jumlah Karyawan Failed", value: "JUMLAH_FAILED" },
    //   { text: "% Failed", value: "PERSENTASE_FAILED" },
    //   { text: "Jumlah Karyawan None", value: "JUMLAH_NONE" },
    //   { text: "% None", value: "PERSENTASE_NONE" },
    //   // { text: "Keterangan", value: "KETERANGAN" },
    // ],

    itemsTableRPV: [],
  }),

  methods: {
    filterRPV() {
      console.log(this.report.ID_Report);
      console.log(this.vaksinasi_ke);
      console.log(this.date);
      this.btnLoading = true;
      // const userRole = sessionStorage.getItem("role");
      const axios = require("axios");
      const getRPV = {
        code: "3",
        data: {
          vaksin_ke: this.vaksinasi_ke,
          periode: this.date,
          code: '3',
          nik: sessionStorage.getItem("nik"),
          role:sessionStorage.getItem("role")
        },
      };
      // console.log(getRPVC);
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "GetReportVaksinasi", getRPV, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.result.message);
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.itemsTableRPV = response.data.Result;
            this.btnLoading = false;
            this.dataTable = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Program Error",
          });
        });
    },

    // formatDate(val) {
    //   const MyDate = new Date(val);

    //   const MyDateString =
    //     ("0" + MyDate.getDate()).slice(-2) +
    //     "-" +
    //     ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    //     "-" +
    //     MyDate.getFullYear();
    //   return MyDateString;
    // },


    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    showDate() {
      this.menu2 = false;
      console.log(this.dates[0]);
      console.log(this.dates[1]);
    },

    // dataCabang() {
    //   this.cabang = "";
    //   this.direktorat = "";
    //   this.bagian = "";
    //   const getCabang = {
    //     code: "5",
    //     data: {
    //       unit: null,
    //       cabang: null,
    //       bagian: null,
    //       status: null,
    //       periode_awal: null,
    //       periode_akhir: null,
    //       direktorat: null,
    //       role: null,
    //       jenis_vaksin: null,
    //     },
    //   };
    //   const getCabang2 = {
    //     code: "10",
    //     data: {
    //       NIK: sessionStorage.getItem("nik"),
    //     },
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: sessionStorage.getItem("nik"),
    //   };
    //   const role = sessionStorage.getItem("role");
    //   const axios = require("axios");

    //   if (role == "ETD") {
    //     axios
    //       .post(this.$baseurl + "api/v1/GetCabangRPVC", getCabang, {
    //         headers,
    //       })
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         const hasil = response.data.Result;
    //         this.items_cabang = hasil;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Cabang Tidak Ditemukan",
    //         });
    //       });
    //   } else {
    //     axios
    //       .post(this.$baseurl + "api/v1/MasterUser", getCabang2, {
    //         headers,
    //       })
    //       .then((response) => {
    //         const hasil = response.data.result;
    //         this.items_cabang = hasil;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Cabang Tidak Ditemukan",
    //         });
    //       });
    //   }
    // },

    // dataUnit() {
    //   this.unit = "";
    //   const getCabang = {
    //     code: "6",
    //     data: {
    //       unit: null,
    //       cabang: this.cabang.ID_Lokasi,
    //       bagian: null,
    //       status: null,
    //       periode_awal: null,
    //       periode_akhir: null,
    //       direktorat: null,
    //       role: null,
    //       jenis_vaksin: null,
    //     },
    //   };
    //   const getUnit = {
    //     code: "11",
    //     data: {
    //       NIK: sessionStorage.getItem("nik"),
    //     },
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: sessionStorage.getItem("nik"),
    //   };
    //   const role = sessionStorage.getItem("role");
    //   const axios = require("axios");

    //   if (role == "ETD") {
    //     axios
    //       .post(this.$baseurl + "api/v1/GetUnitRPVC", getCabang, {
    //         headers,
    //       })
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         const hasil = response.data.Result;
    //         console.log(hasil);
    //         this.items_unit = hasil;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Unit Tidak Ditemukan",
    //         });
    //       });
    //   } else {
    //     axios
    //       .post(this.$baseurl + "api/v1/MasterUser", getUnit, {
    //         headers,
    //       })
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         const hasil = response.data.result;
    //         // console.log(hasil);
    //         this.items_unit = hasil;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Unit Tidak Ditemukan",
    //         });
    //       });
    //   }
    // },

    // dataDirektorat() {
    //   this.direktorat = "";
    //   this.bagian = "";
    //   this.dataBagian();
    //   const getDirektorat = {
    //     // level: "3",
    //     id_unit: this.unit.AliasUnit,
    //     id_cabang: this.cabang.AREAID,
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: sessionStorage.getItem("nik"),
    //   };
    //   const role = sessionStorage.getItem("role");
    //   const axios = require("axios");

    //   if (role == "ETD") {
    //     axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDirektoratByUnitCabang",
    //         getDirektorat,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_direktorat = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Direktorat Tidak Ditemukan",
    //         });
    //       });
    //   } else if (role == "PIC HO") {
    //     const getBagianHO = {
    //       code: "7",
    //       data: {
    //         NIK: sessionStorage.getItem("nik"),
    //         ROLE: sessionStorage.getItem("role"),
    //       },
    //     };
    //     axios
    //       .post(this.$baseurl + "api/v1/MasterUser", getBagianHO, {
    //         headers,
    //       })
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data.result;
    //           // console.log(hasil);
    //           let hasiltest = hasil.map((item) => item.ID_BAGIAN);
    //           // console.log(hasiltest);
    //           for (var i = 0; i < hasiltest.length; i++) {
    //             hasiltest[i] = hasiltest[i].substring(0, 6);
    //           }
    //           // console.log(hasiltest);
    //           const getDirektoratHO = {
    //             id_direktorat: ";" + hasiltest + ";",
    //             id_cabang: "00",
    //           };
    //           axios
    //             .post(this.$baseurl + "api/v1/GetDirektorat", getDirektoratHO, {
    //               headers,
    //             })
    //             .then((response) => {
    //               if (response.data.Message) {
    //                 this.$swal({
    //                   icon: "info",
    //                   title: "Info!",
    //                   text: "Session Anda Telah Habis",
    //                 }).then(() => {
    //                   sessionStorage.clear();
    //                   this.$router.push({ name: "Login" });
    //                 });
    //               } else {
    //                 const hasil = response.data;
    //                 // console.log(hasil)
    //                 this.items_direktorat = hasil;
    //               }
    //             });
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Direktorat Tidak Ditemukan",
    //         });
    //       });
    //   } else {
    //     this.dataBagian();
    //   }
    // },

    // dataBagian() {
    //   this.bagian = "";
    //   const getBagian = {
    //     id: this.direktorat.OMOPERATINGUNITNUMBER,
    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: sessionStorage.getItem("nik"),
    //   };
    //   const axios = require("axios");
    //   const role = sessionStorage.getItem("role");

    //   if (role == "ETD"  &&  this.cabang.AREAID == '00') {
    //     axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDepartemenByDirektorat",
    //         // "https://ms.hrindomaret.com/api/v1/DVC/Utility/Company/GetDepartemenByDirektorat",
    //         getBagian,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_bagian = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   }
    //   else if(role == "ETD" &&  this.cabang.AREAID != '00'){
    //     const getDeptbyCabang = {
    //     id_unit: this.unit.AliasUnit,
    //     id_cabang: this.cabang.AREAID
    //   };
    //   console.log(getDeptbyCabang)
    //   axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDepartemenByUnitCabang",
    //         // "https://ms.hrindomaret.com/api/v1/DVC/Utility/Company/GetDepartemenByDirektorat",
    //         getDeptbyCabang,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_bagian = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   }
    //   else if (role == "PIC HO") {
    //     const getBagianHO = {
    //       code: "7",
    //       data: {
    //         NIK: sessionStorage.getItem("nik"),
    //         ROLE: role,
    //       },
    //     };
    //     axios
    //       .post(this.$baseurl + "api/v1/MasterUser", getBagianHO, { headers })
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data.result;
    //           let hasiltest = hasil.map((item) => {
    //             return {
    //               OMOPERATINGUNITNUMBER: item.ID_BAGIAN,
    //               NAME: item.BAGIAN,
    //             };
    //           });
    //           this.items_bagian = hasiltest;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   } else {
    //     //HR Cabang
    //     const getBagianHR = {
    //       id_unit: this.unit.AliasUnit,
    //       id_cabang: this.cabang.AREAID
    //     };
    //     axios
    //       .post(
    //         this.$baseurl + "api/v1/GetDepartemenByUnitCabang",
    //         getBagianHR,
    //         { headers }
    //       )
    //       .then((response) => {
    //         // console.log(response.data.GetAllUnitResult);
    //         if (response.data.Message) {
    //           this.$swal({
    //             icon: "info",
    //             title: "Info!",
    //             text: "Session Anda Telah Habis",
    //           }).then(() => {
    //             sessionStorage.clear();
    //             this.$router.push({ name: "Login" });
    //           });
    //         } else {
    //           const hasil = response.data;
    //           this.items_bagian = hasil;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$swal({
    //           icon: "error",
    //           title: "Maaf!",
    //           text: "Bagian Tidak Ditemukan",
    //         });
    //       });
    //   }
    // },

    // dataStatus() {
    //   // const getUnit = {nik: sessionStorage.getItem("nik")}
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     // NIK: sessionStorage.getItem("nik"),
    //   };
    //   const getStatus = {
    //     code: "5",
    //   };
    //   const axios = require("axios");
    //   axios
    //     .post(this.$baseurl + "api/v1/MasterStatus", getStatus, { headers })
    //     .then((response) => {
    //       // console.log(response.data.GetAllUnitResult);
    //       const hasil = response.data.message;
    //       this.items_status = hasil;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.$swal({
    //         icon: "error",
    //         title: "Maaf!",
    //         text: "Unit Tidak Ditemukan",
    //       });
    //     });
    // },

    downloadExcel() {
      // var headings = [
      //   [
      //     "Unit/PT",
      //     "Cabang",
      //     "Direktorat",
      //     "Departemen",
      //     "Jumlah Karyawan",
      //     "Jumlah Karyawan Success",
      //     "% Success",
      //     "Jumlah Karyawan Outstanding",
      //     "% Outstanding",
      //     "Jumlah Karyawan Pending",
      //     "% Pending",
      //     "Jumlah Karyawan Failed",
      //     "% Failed",
      //     "Jumlah Karyawan None",
      //     "% None",
      //   ],
      // ];
      const wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.itemsTableRPV, {
        origin: "A2",
        // skipHeader: true,
      });
      // XLSX.utils.sheet_add_aoa(ws, headings); //heading: array of arrays

      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, "Rekap Pendataan Vaksinasi Kary Cabang.xlsx");
    },
  },
};
</script>
