<template>
  <v-app id="insider">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
            <v-row justify="center" class="my-2">
          <h2 class="h2-center my-1">
            Halaman Tidak Ditemukan
          </h2>
            </v-row>
          <!-- <div class="purple--text mb-2">
            Halaman Tidak Ditemukan
          </div> -->
          <v-img src="image/404.png" aspect-ratio="2"></v-img>
          <v-row justify="center" class="my-3">
            <v-btn color="#6C63FF" class="white--text" @click="back()"
              >Kembali ke Halaman Utama</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    ...mapActions({
      // setToken: "setToken",
      setUser: "setUser",
    }),
    reset() {
      this.$refs.form.reset();
    },
    back() {
      let path = ``;
      path = `/input-realisasi`;
      if (this.$route.path !== path) this.$router.push(path);
    },
  },
};
</script>
