import Vue from 'vue'
// import App from './App.vue'
import AppMain from './AppMain.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import store from './store'
import './registerServiceWorker'

// global variable
// Vue.prototype.$baseurl = "https://hrindomaret.com/api/Stag/DVC/"; //Stag
// Vue.prototype.$baseurl = "https://hrindomaret.com/api/DVC/"; //Live

// Server GCP
Vue.prototype.$baseurl = "https://api.hrindomaret.com/api/Dev/DVC/"; //Dev
// Vue.prototype.$baseurl = "https://api-stag.hrindomaret.com/api/Stag/DVC/"; //Stag
// Vue.prototype.$baseurl = "https://api.hrindomaret.com/api/DVC/"; //Live

// Vue.prototype.$baseurl = "http://116.204.128.50:80/";

Vue.use(VueSweetalert2);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(AppMain)
}).$mount('#app')
